import { EyeOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import type { ColumnsType } from 'antd/lib/table'
import type { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import BaseTable from '../../../components/BaseTable'
import { configuration } from '../../../configuration'
import { modalType, deviceTypeI18n, statusDeviceI18n } from '../../../constants/device'
import { filterScreen } from '../../../constants/filterScreen'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { useRouter } from '../../../hooks/useRouter'
import { handlePathName } from '../../../redux/slices/deviceFilterSlice'
import { selectDevice } from '../../../redux/slices/deviceSlice'
import { routesAdminObj } from '../../../routes/routes'
import { DeviceServices } from '../../../services/DeviceServices'
import { DeviceType } from '../../../types/deviceType'
import { utils } from '../../../utils/utils'
import EditAddModal from '../../devices/components/EditAddModal'
import { TableParamsType } from '../../../types/tableParamsType'
import { useTranslation } from 'react-i18next'
interface IPagination {
  page: number
  total_result: number
}

interface IDeviceParams {
  id: string
}
const DeviceByGroups = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const router: any = useRouter()
  const deviceFilter = useAppSelector((state) => state.deviceFilter)
  const [dataSource, setDataSource] = useState<DeviceType[]>([])
  const [pagination, setPagination] = useState<IPagination>()
  const [isModalVisible, setIsModalVisible] = useState({
    type: '',
    visible: false
  })
  const params = useParams<IDeviceParams>()
  const [dataDetail, setDataDetail] = useState<any>({})

  const actionsClassName = 'text-xl px-2 hover:text-blue-400'

  const queryParams = new URLSearchParams(window.location.search)
  const pageCurrent = Number(queryParams.get('page') || 1)
  const textSearch = queryParams.get('keywords') || deviceFilter.key_word
  const [currentPage, setCurrentPage] = useState(pageCurrent)
  const [loading, setLoading] = useState(true)
  const [tableParams, setTableParams] = useState<TableParamsType>({
    sortField: 'id',
    pagination: {
      current: 1
    }
  })

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<DeviceType>
  ) => {
    setTableParams({
      sortField: 'id',
      pagination: {
        current: 1
      },
      filters,
      ...sorter
    })
  }

  /* eslint-disable @typescript-eslint/dot-notation */
  const getData = async (page: number, value: any, searchParams: any) => {
    try {
      const fieldParams = tableParams['order'] !== undefined ? tableParams['columnKey'] : tableParams['sortField']
      const orderParams = tableParams['order'] === 'ascend' ? 'asc' : 'desc'
      const sortParams = `${fieldParams} ${orderParams}`
      const res = (await DeviceServices.getListDeviceByGroups(params.id, {
        page,
        keywords: value
      }, sortParams, searchParams))

      setDataSource(res.devices)
      setPagination(res.meta)
    } catch (error) {
      utils.handleError(error)
    } finally {
      setLoading(false)
    }
  }
  /* eslint-disable @typescript-eslint/dot-notation */

  const getDevice = async (id: string) => {
    try {
      const res = await DeviceServices.getDetail(id)
      res.device.original_price = utils.formatMoney(parseFloat(res.device.original_price)) || 0
      res.device.remaining_price = utils.formatMoney(parseFloat(res.device.remaining_price)) || 0
      setDataDetail(res.device)
    } catch (error) {
      utils.handleError(error)
    }
  }

  const getDetail = async (id: string) => {
    try {
      await DeviceServices.getDetail(id)
      return true
    } catch (error) {
      utils.handleError(error)
    }
  }

  const handleActions = async (record: DeviceType, type: string) => {
    const success = await getDetail(record.id)
    if (!success) return

    dispatch(selectDevice(record))

    switch (type) {
      case modalType.View:
        history.push(`/devices/${record.id}`, { data: router.history.location.search, from: routesAdminObj.devices.path })
        break

      case modalType.Edit:
        setIsModalVisible({ type: modalType.Edit, visible: true })
        getDevice(record.id)
        break

      case modalType.AddRequest:
        break
      default:
        break
    }
  }

  const columns: ColumnsType<DeviceType> = [
    {
      title: t('pageTitle.personal.table.id'),
      dataIndex: 'id',
      key: 'id',
      width: 80,
      sorter: true
    },
    {
      title: t('pageTitle.personal.table.deviceName'),
      dataIndex: 'name',
      key: 'name',
      width: 350,
      sorter: true
    },
    {
      title: t('pageTitle.personal.table.assignee'),
      dataIndex: 'assignee_name',
      key: 'users.full_name',
      width: 250,
      sorter: true
    },
    {
      title: t('pageTitle.personal.table.status'),
      dataIndex: 'status',
      key: 'status',
      width: 150,
      sorter: true,
      render: (_: any, record: any) => (
        <div className="d-flex">{statusDeviceI18n(record.status)}</div>
      )
    },
    {
      title: t('pageTitle.personal.table.category'),
      dataIndex: 'category',
      key: 'category',
      width: 200,
      sorter: true,
      render: (_: any, record: any) => (
        <div className="d-flex">{deviceTypeI18n(record.category)}</div>
      )
    },
    {
      title: t('pageTitle.personal.table.deviceType'),
      dataIndex: 'device_type',
      key: 'device_type',
      width: 200,
      sorter: true
    },
    {
      title: t('pageTitle.personal.table.purchaseDate'),
      dataIndex: 'purchase_date',
      key: 'purchase_date',
      width: 180,
      sorter: true
    },
    {
      title: t('pageTitle.personal.table.activationDate'),
      dataIndex: 'activation_date',
      key: 'activation_date',
      width: 180,
      sorter: true
    },
    {
      title: t('pageTitle.personal.table.warrantyPeriod'),
      dataIndex: 'warranty_period',
      key: 'warranty_period',
      width: 180,
      sorter: true,
      render: (_: any, record: any) => (
        <div className="d-flex">{utils.monthFormat(record.warranty_period)}</div>
      )
    },
    {
      title: t('pageTitle.personal.table.originalPrice'),
      dataIndex: 'original_price',
      key: 'original_price',
      width: 180,
      sorter: true,
      render: (_: any, record: any) => (
        <div className="d-flex" style={{ whiteSpace: 'nowrap' }}>
          {utils.moneyFormat(record, record.original_price)}
        </div>
      )
    },
    {
      title: t('pageTitle.personal.table.allotmentTime'),
      dataIndex: 'allotment_time',
      key: 'allotment_time',
      width: 180,
      sorter: true,
      render: (_: any, record: any) => (
        <div className="d-flex">{utils.monthFormat(record.allotment_time)}</div>
      )
    },
    {
      title: t('pageTitle.personal.table.remainingPrice'),
      dataIndex: 'remaining_price',
      key: 'remaining_price',
      width: 180,
      sorter: true,
      render: (_: any, record: any) => (
        <div className="d-flex" style={{ whiteSpace: 'nowrap' }}>
          {utils.moneyFormat(record, record.remaining_price)}
        </div>
      )
    },
    {
      title: t('pageTitle.personal.table.borrowDate'),
      dataIndex: 'borrow_date',
      key: 'borrow_date',
      width: 180,
      sorter: true
    },
    {
      title: t('pageTitle.personal.table.returnDate'),
      dataIndex: 'return_date',
      key: 'return_date',
      width: 180,
      sorter: true
    },
    {
      title: t('pageTitle.personal.table.actions'),
      fixed: 'right',
      align: 'center',
      width: 200,
      render: (record) => {
        return (
          <>
            <EyeOutlined
              className={actionsClassName}
              onClick={async () => await handleActions(record, modalType.View)}
            />
          </>
        )
      }
    }
  ]

  const handleCancel = () => {
    setIsModalVisible({ type: '', visible: false })
  }
  const searchParams = {
    category: deviceFilter.category,
    status: deviceFilter.status,
    min_remaining_price: deviceFilter.remaining_price[0],
    max_remaining_price: deviceFilter.remaining_price[1],
    start_purchase_date: deviceFilter.purchase_date[0],
    end_purchase_date: deviceFilter.purchase_date[1],
    start_borrow_date: deviceFilter.borrow_date[0],
    end_borrow_date: deviceFilter.borrow_date[1],
    start_return_date: deviceFilter.return_date[0],
    end_return_date: deviceFilter.return_date[1]
  }
  const handleFilter = () => {
    history.push({
      pathname: history.location.pathname,
      search: `?${new URLSearchParams({ ...router.query, page: '1' }).toString()}`
    })
  }
  useEffect(() => {
    getData(pageCurrent, queryParams.get('keywords'), searchParams)
    setCurrentPage(Number(router.query.page || 1))
  }, [
    /* eslint-disable react-hooks/exhaustive-deps */
    JSON.stringify(tableParams),
    JSON.stringify(deviceFilter.category),
    JSON.stringify(deviceFilter.status),
    JSON.stringify(deviceFilter.remaining_price),
    JSON.stringify(deviceFilter.purchase_date),
    JSON.stringify(deviceFilter.borrow_date),
    JSON.stringify(deviceFilter.return_date),
    JSON.stringify(router.query)
    /* eslint-enable react-hooks/exhaustive-deps */
  ])

  useEffect(() => {
    document.title = `Devices management - ${t('pageTitle.devices')}`
    dispatch(handlePathName(window.location.pathname))
  }, [])

  if (loading) {
    return (
      <div className="flex justify-center mt-4">
        <Spin></Spin>
      </div>
    )
  }

  return (
    <div>
      <EditAddModal
        dataDetail={dataDetail}
        isModalVisible={isModalVisible.visible}
        type={isModalVisible.type}
        handleCancel={handleCancel}
        getData={async () => await getData(1, textSearch, searchParams)}
      />
      <BaseTable
        columns={columns}
        dataSource={dataSource}
        total={pagination?.total_result}
        pageSize={configuration.pageSize}
        handleAddNewDevice={false}
        callbackFilter={handleFilter}
        filterScreen={filterScreen.devices}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        handleTableChange={handleTableChange}
      />
    </div>
  )
}

export default DeviceByGroups
