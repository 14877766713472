import { RequestPostType, CloseRequestType } from '../types/requestType'
import { apiConstants } from './ApiConstants'
import { ApiServices } from './ApiServices'

export const RequestServices = {
  async getRequests (page: number, value: any, sortParams: any, searchParams: any) {
    return await ApiServices().get(`${apiConstants.REQUESTS}`, {
      params: {
        page,
        keywords: value,
        sort_order: sortParams,
        tracker: searchParams.tracker,
        status: searchParams.status,
        priority: searchParams.priority,
        start_start_date: searchParams.start_start_date,
        end_start_date: searchParams.end_start_date,
        start_due_date: searchParams.start_due_date,
        end_due_date: searchParams.end_due_date
      }
    })
  },
  async createNewRequest (data: RequestPostType) {
    return await ApiServices().post(apiConstants.REQUESTS, data)
  },
  async updateRequest (id, data: RequestPostType) {
    return await ApiServices().patch(`${apiConstants.REQUESTS}/${id}`, data)
  },
  deleteRequest: async (id: number) => {
    return await ApiServices().delete(`${apiConstants.REQUESTS}/${id}`)
  },
  getDetail: async (id: number | string) => {
    return await ApiServices().get(`${apiConstants.REQUESTS}/${id}`)
  },
  closeRequest: async (id: number, data: CloseRequestType) => {
    return await ApiServices().patch(
      `${apiConstants.REQUESTS}/${id}/close_request`,
      data
    )
  }
}
