import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RequestShowType } from '../../types/requestType'

interface SelectedRequestType {
  selectedRequest: RequestShowType
}
const initialState: SelectedRequestType = {
  selectedRequest: {
    id: '',
    device_name: '',
    device_id: null,
    author_id: null,
    author_name: '',
    assignee_id: null,
    assignee_name: '',
    tracker: '',
    priority: '',
    status: '',
    description: '',
    start_date: '',
    due_date: '',
    decision: '',
    manager_notes: '',
    title: ''
  }
}

const requestSlice = createSlice({
  name: 'requestSlice',
  initialState,
  reducers: {
    selectRequest: (state, action: PayloadAction<RequestShowType>) => {
      state.selectedRequest = action.payload
    }
  }
})

export const { selectRequest } = requestSlice.actions
export default requestSlice.reducer
