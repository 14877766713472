import i18n from '../lang/i18n'

interface IRole {
  user: { label: string, value: string }
  manager: { label: string, value: string }
  operator: { label: string, value: string }
  admin: { label: string, value: string }
}
export const role: IRole = {
  user: { label: i18n.t('role.user'), value: 'user' },
  manager: { label: i18n.t('role.manager'), value: 'manager' },
  operator: { label: i18n.t('role.operator'), value: 'operator' },
  admin: { label: i18n.t('role.admin'), value: 'admin' }
}

export const roleList = Object.keys(role).map((obj) => role[obj])
export const roleSelect = [...roleList, { label: i18n.t('common.all'), value: 'all' }]
export const roleI18n = (value: string) => {
  return roleList.find((role) => role.value === value).label || ''
}
