import { HistoryDeviceType } from '../types/historyDeviceType'
import { apiConstants } from './ApiConstants'
import { ApiServices } from './ApiServices'

export const HistoryDeviceServices = {
  async getListHistoryDevices (deviceId: string, page: number, value: any) {
    return await ApiServices().get(`${apiConstants.HISTORY_DEVICES.replace(':id', deviceId)}`, {
      params: {
        page,
        keywords: value
      }
    })
  },
  async createHistoryDevice (deviceId: string, data: HistoryDeviceType) {
    return await ApiServices().post(`${apiConstants.HISTORY_DEVICES.replace(':id', deviceId)}`, data)
  },
  async updateHistoryDevice (deviceId: string, id: string, data: HistoryDeviceType) {
    return await ApiServices().patch(`${apiConstants.HISTORY_DEVICES.replace(':id', deviceId)}/${id}`, data)
  },
  getDetail: async (id: string) => {
    const res = await ApiServices().get(`${apiConstants.HISTORY_DEVICES.replace('/devices/:id', '')}/${id}`)
    return res.data
  }
}
