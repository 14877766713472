import { PriorityType, StatusType, TrackerType } from '../types/requestType'
import { utils } from '../utils/utils'
import i18n from '../lang/i18n'

export const COMMON_STATUS_OBJECT: StatusType = {
  OPEN: {
    value: 'open',
    label: i18n.t('common.open')
  },
  IN_PROGRESS: {
    value: 'in_progress',
    label: i18n.t('common.inProgress')
  },
  APPROVED_BY_MANAGER: {
    value: 'approved_by_manager',
    label: i18n.t('common.approvedByManager')
  },
  APPROVED_BY_ADMIN: {
    value: 'approved_by_admin',
    label: i18n.t('common.approvedByAdmin')
  },
  PREPARING: {
    value: 'preparing',
    label: i18n.t('common.preparing')
  },
  REJECT: {
    value: 'rejected',
    label: i18n.t('common.rejected')
  },
  CLOSED: {
    value: 'closed',
    label: i18n.t('common.closed')
  }
}
export const COMMON_PRIORITY_OBJECT: PriorityType = {
  LOW: {
    value: 'low',
    label: i18n.t('priority.low')
  },
  NORMAL: {
    value: 'normal',
    label: i18n.t('priority.normal')
  },
  HIGH: {
    value: 'high',
    label: i18n.t('priority.high')
  },
  URGENT: {
    value: 'urgent',
    label: i18n.t('priority.urgent')
  },
  IMMEDIATE: {
    value: 'immediate',
    label: i18n.t('priority.immediate')
  }
}
export const COMMON_TRACKER_OBJECT: TrackerType = {
  BORROW: {
    value: 'borrow',
    label: i18n.t('tracker.borrow')
  },
  BUY: {
    value: 'buy',
    label: i18n.t('tracker.buy')
  },
  RETURN: {
    value: 'return',
    label: i18n.t('tracker.return')
  },
  WARRANTY_CLAIM: {
    value: 'warranty_claim',
    label: i18n.t('tracker.warranty_claim')
  }
}
export const COMMON_TRACKERS: any = utils.convertDataObjectToArray(
  COMMON_TRACKER_OBJECT
)
export const COMMON_PRIORITIES: any = utils.convertDataObjectToArray(
  COMMON_PRIORITY_OBJECT
)
export const COMMON_STATUSES: any =
  utils.convertDataObjectToArray(COMMON_STATUS_OBJECT)

export const dateFormat = 'YYYY/MM/DD'
