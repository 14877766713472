import { Button, Form } from 'antd'
import { TenantServices } from '../../../../services/TenantServices'
import { utils } from '../../../../utils/utils'
import { UserServices } from '../../../../services/UserServices'
import { updateUserInfo } from '../../../../redux/slices/authSlice'
import { useAppDispatch } from '../../../../hooks'
import { useTranslation } from 'react-i18next'

const DeleteForm = (prop: any) => {
  const { tenant, handleCancelModal, forceRequestList } = prop
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const getUserInfo = async () => {
    try {
      const { data } = await UserServices.getDetail()
      dispatch(updateUserInfo(data.user))
    } catch (e) {
      utils.handleError(e)
    }
  }
  const handleFinish = async () => {
    try {
      await TenantServices.deleteTenant(tenant.id)
      utils.handleSuccessNotify(t('message.deleteFormTenants.success'))
      forceRequestList && forceRequestList()
    } catch (error) {
      utils.handleError(error)
    } finally {
      handleCancelModal && handleCancelModal()
      getUserInfo()
    }
  }

  return (
    <Form form={form} layout="vertical" onFinish={handleFinish}>
      <Form.Item name="Tenant">
        <span className="text-sm">{t('message.deleteFormTenants.confirmDelete')}</span>
      </Form.Item>
      <div className="d-flex justify-end">
        <Button type="default" onClick={handleCancelModal} className="mr-2">
          {t('cancel')}
        </Button>
        <Button type="primary" htmlType="submit">
        {t('delete')}
        </Button>
      </div>
    </Form>
  )
}

export default DeleteForm
