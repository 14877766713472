import { Button, Col, DatePicker, Form, Row, Select } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import moment from 'moment'
import { useEffect, useState } from 'react'
import SelectSearchData from '../../../../components/SelectSearchData'
import { configuration } from '../../../../configuration'
import { statusDevice } from '../../../../constants/device'
import { utils } from '../../../../utils/utils'
import { DeviceServices } from '../../../../services/DeviceServices'
import { RequestServices } from '../../../../services/RequestServices'
import './index.scss'
import { selectRequest } from '../../../../redux/slices/requestSlice'
import { useAppDispatch } from '../../../../hooks'
import { useTranslation } from 'react-i18next'
const dateFormat = 'YYYY/MM/DD'

const CloseRequestForm = (props: any) => {
  const { t } = useTranslation()
  const { forceRefeshTable, dataRequest = {} } = props
  const [form] = Form.useForm()
  const [deviceOptions, setDeviceOptions] = useState<DefaultOptionType[]>([])
  const [userOptions, setUserOptions] = useState<DefaultOptionType[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const dispatch = useAppDispatch()

  const fetchUser = async (value: string) => {
    try {
      const res = await DeviceServices.getListUsersForSelect({
        page: 1,
        limit: configuration.pageSizeSearch,
        keywords: value
      })
      const options = res.users.map((user: { id: { toString: () => any }, full_name: any }) => ({
        value: user.id.toString(),
        label: user.full_name
      }))

      setUserOptions(options)
    } catch (error) {
      utils.handleError(error)
    }
  }

  const fetchDevice = async (value: string) => {
    try {
      const res = await DeviceServices.getListDevicesName({
        name: value
      })
      const formatterData = (res?.devices || []).map(
        (item: { id: { toString: () => any }, name: any }) => ({
          value: item.id.toString(),
          label: item.name
        })
      )

      setDeviceOptions(formatterData)
    } catch (error) {
      utils.handleError(error)
    }
  }

  const formatValue = (value: {
    device_id: number
    assignee_id: number
    status: string
    borrow_date: moment.MomentInput
    return_date: moment.MomentInput
  }) => {
    return {
      device_id: value.device_id,
      assignee_id: value.assignee_id,
      status: value.status,
      borrow_date: value.borrow_date ? moment(value.borrow_date).format(dateFormat) : '',
      return_date: value.return_date ? moment(value.return_date).format(dateFormat) : ''
    }
  }

  const formatValueWarranty = (value: {
    device_id: number
    assignee_id: number
    status: string
  }) => {
    return {
      device_id: value.device_id,
      assignee_id: value.assignee_id,
      status: value.status
    }
  }
  const isBorrow = dataRequest.tracker === 'borrow'
  const isWarranty = dataRequest.tracker === 'warranty_claim'
  const isReturn = dataRequest.tracker === 'return'
  const handleFinish = async (value: any) => {
    try {
      setIsSubmitting(true)
      const formatterValue = isWarranty ? formatValueWarranty(value) : formatValue(value)
      const { data } = await RequestServices.closeRequest(dataRequest.id, formatterValue)
      dispatch(selectRequest(data.request))
      utils.handleSuccessNotify(t('message.closeRequestForm.success'))
      forceRefeshTable && forceRefeshTable()
    } catch (e) {
      utils.handleError(e)
    } finally {
      setIsSubmitting(false)
    }
  }

  const checkReturnDate = async () => {
    const borrowDate = form.getFieldValue('borrow_date')
    const returnDate = form.getFieldValue('return_date')

    if (!returnDate) {
      return await Promise.resolve()
    } else if (!borrowDate || new Date(moment(borrowDate).format(dateFormat)) > new Date(moment(returnDate).format(dateFormat))) {
      return await Promise.reject(
        t('message.closeRequestForm.checkReturnDate')
      )
    }

    form.setFields([
      { name: 'borrow_date', errors: [''], validating: true },
      { name: 'return_date', errors: [''], validating: true }
    ])
    return await Promise.resolve()
  }

  useEffect(() => {
    fetchDevice(dataRequest.device_name)
    fetchUser(dataRequest.assignee_name)
  }, [dataRequest.device_name, dataRequest.assignee_name])

  useEffect(() => {
    form.setFieldsValue({
      device_id: dataRequest.device_id?.toString(),
      assignee_id: dataRequest.assignee_id?.toString(),
      borrow_date: '',
      return_date: ''
    })
  }, [dataRequest.device_id, form])

  const optionsStatus = () => {
    let options = ''
    switch (true) {
      case isBorrow:
        options = 'using'
        break
      case isReturn:
        options = 'available'
        break
      case isWarranty:
        options = 'warranty'
        break
      default:
        break
    }
    return options
  }

  return (
    <Form
      scrollToFirstError
      form={form}
      layout="vertical"
      onFinish={handleFinish}
    >
      <Row gutter={20}>
        <Col span={12}>
          <Form.Item
            label={t('pageTitle.personal.modalRequest.device')}
            name="device_id"
            rules={[{ required: true, message: t('message.requiredRule') }]}
          >
            <SelectSearchData options={deviceOptions} fetchData={fetchDevice} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t('pageTitle.personal.table.assignee')}
            name="assignee_id"
            rules={[{ required: true, message: t('message.requiredRule') }]}
          >
            <SelectSearchData options={userOptions} fetchData={fetchUser} />
          </Form.Item>
        </Col>
        <Col span={12}>
          { (isBorrow || isReturn) && (<Form.Item
            label={t('pageTitle.personal.table.borrowDate')}
            name="borrow_date"
            rules={[
              { required: isBorrow, message: t('message.requiredRule') },
              { validator: checkReturnDate }
            ]}
          >
            <DatePicker disabled={!isBorrow} format={dateFormat} className="w-full" placeholder={t('datepickerPlaceholder')} />
          </Form.Item>
          )}
        </Col>
        <Col span={12}>
          { (isBorrow || isReturn) && (
            <Form.Item
              label={t('pageTitle.personal.table.returnDate')}
              name="return_date"
              rules={[{ validator: checkReturnDate }]}
            >
              <DatePicker disabled={!isBorrow} format={dateFormat} className="w-full" placeholder={t('datepickerPlaceholder')} />
            </Form.Item>
          )}
        </Col>
        <Col span={12}>
          <Form.Item label={t('pageTitle.personal.table.status')} name="status" initialValue={optionsStatus()} >
            <Select getPopupContainer={trigger => trigger.parentNode} disabled={true} >
              {statusDevice.map((item: any, index: number) => (
                <Select.Option value={item.value} key={index}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Button
        type="primary"
        htmlType="submit"
        loading={isSubmitting}
        className="block ml-auto"
      >
        {t('message.requestForm.requestAndDevice')}
      </Button>
    </Form>
  )
}

export default CloseRequestForm
