import { apiConstants } from './ApiConstants'
import { RequestService } from './RequestService'

export const AuthOtherService = {
  async loginOauth2 (code: any) {
    return await RequestService().get(`${apiConstants.loginOauth2Me}?code=${code}`)
  },
  async updateSyncLang (userId: any, lang: any) {
    return await RequestService().post(`${apiConstants.CHANGE_LANGUAGE}`, { uid: userId, language: lang })
  },
  async getCurrentLanguage (userId: any) {
    return await RequestService().get(`${apiConstants.LANG}?uid=${userId}`)
  },
  async checkIsLogin (userId: any) {
    return await RequestService().get(`${apiConstants.IS_LOGIN}?user=${userId}`)
  },
  async syncLogout (userId: any) {
    return await RequestService().post(`${apiConstants.USER_LOGOUT}`, { user: userId })
  }
}
