import { Select } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import React, { useState } from 'react'
import { debounceFunction } from '../../utils/debounceFunction'

interface ISelectSearchData {
  fetchData: (value: string) => Promise<void>
  options: DefaultOptionType[]
  disabled?: boolean
}
const SelectSearchData = ({
  fetchData,
  options,
  ...props
}: ISelectSearchData) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleSearch = async (value: string) => {
    setIsLoading(true)

    debounceFunction(() => {
      fetchData(value)
      setIsLoading(false)
    }, 1500)
  }

  return (
    <Select
      onChange={(value) => console.log(value)}
      showSearch
      loading={isLoading}
      onSearch={handleSearch}
      options={options}
      filterOption={false}
      getPopupContainer={trigger => trigger.parentNode}
      {...props}
    />
  )
}

export default SelectSearchData
