import { apiConstants } from './ApiConstants'
import { ApiServices } from './ApiServices'

export const AuthServices = {
  async loginViaGG (tokenGoogle: any, dataUser: any) {
    return await ApiServices({}, false).post(`${apiConstants.LOGIN_VIA_GG}`, {
      token: tokenGoogle,
      data_user: dataUser
    })
  },
  async logout () {
    return await ApiServices({}, true).delete(`${apiConstants.LOGOUT}`)
  },
  async loginWithID (data: any) {
    return await ApiServices().post(apiConstants.loginVV, data)
  }
}
