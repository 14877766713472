import React, { useState } from 'react'
import { Dropdown, Menu, Space } from 'antd'
import i18n from 'i18next'
// import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
// import { useRouter } from 'next/router'
// import { routeObj } from '@/routes/routes'
import BaseModal from '../../BaseModal'
import { languageObj } from '../../../constants/locales/languages'
import { utils } from '../../../utils/utils'
import { storage } from '../../../constants/storage'
import { AuthOtherService } from '../../../services/AuthOtherService'
// import { useTranslation } from 'react-i18next'
import './index.scss'

require('dayjs/locale/ja')

interface MenuItem {
  key: string
  label: string
}

const SelectLanguage = () => {
  // const { t } = useTranslation()
  const [openModal, setOpenModal] = useState(false)

  const items: MenuItem[] = utils.convertObjectToArrayData(languageObj)
  const listLanguage = items.map((item) => ({ ...item, disabled: i18n.language === item.key }))
  const userId: any = utils.getStorage(storage.ID_USER) || ''
  const [language, setLanguage] = useState(localStorage.getItem(storage.language) || 'en')
  const handleCloseModal = () => {
    setOpenModal(false)
  }
  const handleUpdateSyncLanguage = async (lang: any) => {
    try {
      const res = await AuthOtherService.updateSyncLang(userId, lang)
      const language = res.data.lang
      if (language) {
        window.location.reload()
      }
    } catch (error) {
      utils.handleError(error)
    }
  }

  const changeLanguage = (e: string) => {
    localStorage.setItem(storage.language, e)
    setLanguage(e)
    dayjs.locale(e)
    handleUpdateSyncLanguage(e)
    // window.location.reload()
  }

  const languageMenu = (
    <div className="nav-profile nav-dropdown language-dropdown">
      <div className="nav-profile-body">
      <Menu onClick={({ key }) => changeLanguage(key)}>
      {listLanguage.map((item) => (
        <Menu.Item key={item.key}>{item.label}</Menu.Item>
      ))}
    </Menu>
      </div>
    </div>
  )

  return (
    <>
      <Dropdown
        className={'text-white mr-5'}
        overlay={languageMenu}
        trigger={['click']}
      >
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            <div className="truncateText max-w-[100px] text-center">{language === 'en'
              ? <img src="/usa.png" alt="Logo" className="truncateText max-w-[100px] text-center" style={{ width: '30px', marginBottom: '5px' }} />
              : <img src="/japan.png" alt="Logo" className="truncateText max-w-[100px] text-center" style={{ width: '30px', marginBottom: '5px' }} /> }</div>
          </Space>
        </a>
      </Dropdown>
      {openModal && (
        <BaseModal
          title='changeLanguage'
          visible={openModal}
          onOk={changeLanguage}
          onCancel={handleCloseModal}
        >
          changeLanguage
        </BaseModal>
      )}
    </>
  )
}

export default SelectLanguage
