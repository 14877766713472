import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import deviceReducer from '../slices/deviceSlice'
import requestReducer from '../slices/requestSlice'
import authReducer from '../slices/authSlice'
import userReducer from '../slices/userSlice'
import requestFilterReducer from '../slices/requestFilterSlice'
import userFilterReducer from '../slices/userFilterSlice'
import deviceFilterReducer from '../slices/deviceFilterSlice'
import deviceHistoryReducer from '../slices/deviceHistorySlice'
import keysMenuReducer from '../slices/keysMenuSlice'
import tenantFilterSlice from '../slices/tenantFilterSlice'

const rootReducer = combineReducers({
  device: deviceReducer,
  request: requestReducer,
  auth: authReducer,
  user: userReducer,
  requestFilter: requestFilterReducer,
  userFilter: userFilterReducer,
  deviceFilter: deviceFilterReducer,
  deviceHistory: deviceHistoryReducer,
  keysMenu: keysMenuReducer,
  tenantFilter: tenantFilterSlice
})

export const store = configureStore({
  reducer: rootReducer
})

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
