import { Spin } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { deviceTypeI18n, statusDeviceI18n } from '../../../constants/device'
import { role } from '../../../constants/role'
import { useAppSelector } from '../../../hooks'
import { useRouter } from '../../../hooks/useRouter'
import { DeviceServices } from '../../../services/DeviceServices'
import { DeviceType } from '../../../types/deviceType'
import { utils } from '../../../utils/utils'
import ContentDetails from '../../request/components/ContentDetails'
import HistoryDevices from './historyDevices'
import { pageNotFound, routesAdminObj } from '../../../routes/routes'
import { useTranslation } from 'react-i18next'

interface IDeviceParams {
  id: string
}

const DeviceDetails = () => {
  const { t } = useTranslation()
  const [device, setDevice] = useState<DeviceType>()
  const params = useParams<IDeviceParams>()
  const router = useRouter()
  const auth = useAppSelector((state) => state.auth)
  const [loading, setLoading] = useState(true)

  const getDevice = async () => {
    try {
      const res = await DeviceServices.getDetail(params.id)
      setDevice(res.device)
    } catch (error) {
      utils.handleError(error)
      router.history.push(pageNotFound)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    document.title = `Devices management - ${t('pageTitle.deviceDetails')}`
    getDevice()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const info = [
    { label: t('pageTitle.personal.table.deviceCode'), value: device?.device_type ? `${device?.device_type} - ${device?.code}` : device?.code },
    { label: t('pageTitle.personal.table.category'), value: deviceTypeI18n(device?.category) },
    { label: t('pageTitle.personal.table.serialNumber'), value: device?.serial_number },
    { label: t('pageTitle.personal.table.purchaseDate'), value: device?.purchase_date },
    { label: t('pageTitle.personal.table.activationDate'), value: device?.activation_date },
    { label: t('pageTitle.personal.table.warrantyPeriod'), value: utils.monthFormat(device?.warranty_period) },
    { label: t('pageTitle.personal.table.supplier'), value: device?.supplier },
    {
      label: t('pageTitle.personal.table.invoiceNumber'),
      value: device?.invoice_number,
      hidden: role.user.value === auth.userInfo.role
    },
    {
      label: t('pageTitle.personal.table.originalPrice'),
      value: utils.moneyFormat(device, device?.original_price || 0),
      unit: device?.original_price_unit,
      hidden: role.user.value === auth.userInfo.role
    },
    {
      label: t('pageTitle.personal.table.allotmentTime'),
      value: utils.monthFormat(device?.allotment_time),
      hidden: role.user.value === auth.userInfo.role
    },
    {
      label: t('pageTitle.personal.table.remainingPrice'),
      value: utils.moneyFormat(device, device?.remaining_price || 0),
      unit: device?.original_price_unit,
      hidden: role.user.value === auth.userInfo.role
    },
    { label: t('pageTitle.personal.table.assigneeName'), value: device?.assignee_name },
    { label: t('pageTitle.personal.table.borrowDate'), value: device?.borrow_date },
    { label: t('pageTitle.personal.table.status'), value: statusDeviceI18n(device?.status) },
    { label: t('pageTitle.personal.table.returnDate'), value: device?.return_date },
    {
      label: t('pageTitle.personal.table.managerNotes'),
      value: device?.manager_notes,
      hidden: role.user.value === auth.userInfo.role
    }
  ]
  const titleInfo = `#${device?.id}: ${device?.name}`

  if (loading) {
    return (
      <div className="flex justify-center mt-4">
        <Spin></Spin>
      </div>
    )
  }
  const goBack = () => {
    const state: any = router.history.location.state || {}
    router.push({
      pathname: state.from === routesAdminObj.myDevices.path ? routesAdminObj.myDevices.path : routesAdminObj.devices.path,
      search: state.data || ''
    })
  }

  return (
    <>
      <ContentDetails
        data={info}
        title={titleInfo}
        description={device?.description}
        photos={device?.photos}
        handleGoBack={goBack}
      />
      {auth.userInfo.role !== role.user.value && <HistoryDevices />}
    </>
  )
}

export default DeviceDetails
