import axios from 'axios'

export const RequestService = (
  params = {
    config: {
      headers: { 'Content-Type': 'application/json' }
    }
  }
) => {
  const { config } = params

  const instanceAxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_REQUEST_VID,
    ...config
  })

  // Add a request interceptor
  instanceAxios.interceptors.request.use(
    function (config) {
      return config
    },
    async function (error) {
      return await Promise.reject(error)
    }
  )

  // Add a response interceptor
  instanceAxios.interceptors.response.use(
    function (response) {
      return response
    },

    async function (error) {
      return await Promise.reject(error)
    }
  )
  return instanceAxios
}
