import { Spin } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import type { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface'
import { useEffect, useState } from 'react'
import BaseTable from '../../components/BaseTable'
import BaseModal from '../../components/BaseModal'
import FormItem from './components/Form'
import DeleteForm from './components/DeleteForm'
import { GroupsServices } from '../../services/GroupsServices'
import { utils } from '../../utils/utils'
import { filterScreen } from '../../constants/filterScreen'
import { configuration } from '../../configuration'
import { TableParamsType } from '../../types/tableParamsType'
import { GroupsType } from '../../types/groupsType'
import { useRouter } from '../../hooks/useRouter'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { routesObj } from '../../routes/routes'

function Groups () {
  const router: any = useRouter()
  const history: any = useHistory()
  const { t } = useTranslation()
  const queryParams = new URLSearchParams(window.location.search)
  const pageCurrent = Number(queryParams.get('page') || 1)
  const textSearch = queryParams.get('keywords') || ''
  const [currentPage, setCurrentPage] = useState(pageCurrent)
  const [isNewFormModalVisible, setIsNewFormModalVisible] = useState<boolean>()
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>()
  const actionsClassName = 'text-xl px-2 hover:text-blue-400'

  const [listGroup, setListGroup] = useState([])

  const [totalItems, setTotalItems] = useState(0)
  const [loading, setLoading] = useState(true)
  const [group, setGroup] = useState({})

  const [tableParams, setTableParams] = useState<TableParamsType>({
    sortField: 'id',
    pagination: {
      current: 1
    }
  })

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<GroupsType>
  ) => {
    setTableParams({
      sortField: 'id',
      pagination: {
        current: 1
      },
      filters,
      ...sorter
    })
  }
  const handleFilter = () => {
    router.push({
      pathname: router.location.pathname,
      search: `?${new URLSearchParams({ ...router.query, page: '1' }).toString()}`
    })
  }

  const getGroup = async (id: number | string) => {
    try {
      const { data } = await GroupsServices.getDetail(id)
      setGroup(data.group)
    } catch (error) {
      utils.handleError(error)
    }
  }
  /* eslint-disable @typescript-eslint/dot-notation */
  const handleActions = async (record: any, modalType: string) => {
    await getGroup(record.id)
    if (modalType === 'Delete') {
      setIsDeleteModalVisible(true)
    } else {
      setIsNewFormModalVisible(true)
    }
  }

  const fetchListGroup = async (page = 1, keywords: string) => {
    try {
      const fieldParams = tableParams['order'] !== undefined ? tableParams['columnKey'] : tableParams['sortField']
      const orderParams = tableParams['order'] === 'ascend' ? 'asc' : 'desc'
      const sortParams = `${fieldParams} ${orderParams}`

      const response = (await GroupsServices.getListGroup(page, keywords, sortParams)).data || []

      setListGroup(response.groups)
      setTotalItems(response.meta.total_result)
    } catch (error) {
      utils.handleError(error)
    } finally {
      setLoading(false)
    }
  }

  const handleCancel = (modalType: string) => {
    setGroup({})
    if (modalType === 'Delete') {
      setIsDeleteModalVisible(false)
    } else {
      setIsNewFormModalVisible(false)
    }
  }

  const handleNameClick = (record: any) => {
    history.push(
      routesObj.groupDevices.path.replace(':id', record.id)
    )
  }

  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 130,
      sorter: true
    },
    {
      title: t('label.name'),
      dataIndex: 'name',
      key: 'name',
      width: 300,
      sorter: true,
      render: (text: string, record: any) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a style={{ wordBreak: 'break-word' }} onClick={() => handleNameClick(record)}>{text}</a>
      )
    },
    {
      title: t('label.description'),
      dataIndex: 'description',
      key: 'description',
      width: 300,
      sorter: true,
      render: (record: any) => {
        return (
          <div style={{ wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: record }} />
        )
      }
    },
    {
      title: t('label.actions'),
      align: 'center',
      width: 200,
      render: (record: any) => {
        return (
          <>
            <EditOutlined
              className={actionsClassName}
              onClick={async () => await handleActions(record, 'Edit')}
            />
            <DeleteOutlined
              className={`${actionsClassName}`}
              onClick={async () => await handleActions(record, 'Delete')}
            />
          </>
        )
      }
    }
  ]

  useEffect(() => {
    fetchListGroup(Number(router.query.page || 1), textSearch)
    setCurrentPage(Number(router.query.page || 1))
  }, [
    JSON.stringify(tableParams),
    JSON.stringify(router.query)
  ])

  useEffect(() => {
    document.title = `Devices management - ${t('pageTitle.groups')}`
  }, [])

  if (loading) {
    return (
      <div className="flex justify-center mt-4">
        <Spin></Spin>
      </div>
    )
  }

  return (
    <div>
      <BaseTable
        columns={tableColumns}
        dataSource={listGroup}
        total={totalItems}
        pageSize={configuration.pageSize}
        callbackFilter={handleFilter}
        filterScreen={filterScreen.groups}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        handleTableChange={handleTableChange}
        isScrollTable={false}
        handleAddNewDevice={() => setIsNewFormModalVisible(true)}
      />
      {isNewFormModalVisible && (
        <BaseModal
          visible={isNewFormModalVisible}
          handleCancel={() => handleCancel('Edit')}
          content={
            <FormItem
              group={group}
              forceRefeshTable={() => {
                fetchListGroup(Number(router.query.page || 1), textSearch)
                setCurrentPage(Number(router.query.page || 1))
                handleCancel('Edit')
              }}
            />
          }
          title={Object.keys(group).length ? t('groups.update') : t('groups.create')}
          width={1000}
          footer={null}
        />
      )}

      {isDeleteModalVisible && (
        <BaseModal
          visible={isDeleteModalVisible}
          handleCancel={() => handleCancel('Delete')}
          content={
            <DeleteForm
              group={group}
              forceRequestList={() => {
                fetchListGroup(Number(router.query.page || 1), textSearch)
                setCurrentPage(Number(router.query.page || 1))
                handleCancel('Delete')
              }}
              handleCancelModal={() => handleCancel('Delete')}
            />
          }
          title={t('groups.titleDelete')}
          width={350}
          footer={null}
          bodyStyle={{ padding: '5px 24px 14px' }}
        />
      )}
    </div>
  )
}

export default Groups
