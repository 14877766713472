import { Button, Form } from 'antd'
import { DeviceServices } from '../../../../services/DeviceServices'
import { utils } from '../../../../utils/utils'
import { useTranslation } from 'react-i18next'
interface ModalProps {
  dataRecord: any
  forceRequestList?: any
  handleCancelModal?: any
}

const DeleteForm = ({ dataRecord, forceRequestList, handleCancelModal }: ModalProps) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const handleFinish = async () => {
    try {
      await DeviceServices.deleteDevice(dataRecord.id)
      utils.handleSuccessNotify(t('message.deleteForm.success'))
      forceRequestList && forceRequestList()
    } catch (error) {
      utils.handleError(error)
    } finally {
      handleCancelModal && handleCancelModal()
    }
  }

  return (
    <>
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <>
          <Form.Item name="Request">
            <span className="text-sm">{t('pageTitle.personal.modalDeleteDevice.content')}</span>
          </Form.Item>
          <div className="flex justify-end">
            <Button type="default" onClick={handleCancelModal} className="mr-2">
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Delete
            </Button>
          </div>
        </>
      </Form>
    </>
  )
}

export default DeleteForm
