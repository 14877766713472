import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DeviceType } from '../../types/deviceType'

interface SelectedDeviceType {
  selectedDevice: DeviceType
}
const initialState: SelectedDeviceType = {
  selectedDevice: {
    name: '',
    id: '0',
    assignee_id: '',
    assignee_email: '',
    device_type: '',
    status: 'in-use',
    purchase_date: 'Aug 05 2020'
  }
}

const deviceSlice = createSlice({
  name: 'deviceSlice',
  initialState,
  reducers: {
    selectDevice: (state, action: PayloadAction<DeviceType>) => {
      state.selectedDevice = action.payload
    }
  }
})

export const { selectDevice } = deviceSlice.actions
export default deviceSlice.reducer
