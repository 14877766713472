import 'antd/dist/antd.min.css'
import { Redirect, Route, Switch } from 'react-router-dom'
import AppRoute from './components/AppRoute'
import PageNotFound from './pages/404'
import './styles/global.scss'
import './styles/antdCustom.scss'
import './lang/i18n'
import { routes, routesAdminObj, routesObj } from './routes/routes'
import { UserServices } from './services/UserServices'
import { updateUserInfo } from './redux/slices/authSlice'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from './hooks'
import { utils } from './utils/utils'
import { Spin } from 'antd'
import { useRouter } from './hooks/useRouter'
import { storage } from './constants/storage'
import { AuthOtherService } from './services/AuthOtherService'

function Pages () {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const auth = useAppSelector((state) => state.auth)
  const router = useRouter()
  const userId: any = utils.getStorage(storage.ID_USER) || ''

  const getUserInfo = async () => {
    try {
      const { data } = await UserServices.getDetail()
      dispatch(updateUserInfo(data.user))
    } catch (e) {
      utils.handleError(e)
    }
  }

  const handleSyncLanguage = async (lang: any) => {
    try {
      const res = await AuthOtherService.getCurrentLanguage(userId)
      const language = res.data.lang
      localStorage.setItem(storage.language, language)
      if (lang && language !== lang && userId) {
        window.location.reload()
      }
    } catch (error) {
      utils.handleError(error)
    }
  }

  const checkIsLogin = async () => {
    const res = await AuthOtherService.checkIsLogin(userId)
    const isLogin = res?.data?.login?.online
    if (isLogin !== undefined && !isLogin) {
      utils.logout()
    }
  }

  useEffect(() => {
    if (utils.getStorage(storage.ACCESS_TOKEN) && utils.getStorage(storage.REFRESH_TOKEN)) {
      getUserInfo()
    } else {
      setLoading(false)
    }
  }, [utils.getStorage(storage.ACCESS_TOKEN)])

  useEffect(() => {
    const routerDetails =
      routes.find((route) => route.path === router.pathname) || {}
    if (
      Object.keys(auth.userInfo).length &&
      routerDetails.roleAccess &&
      !routerDetails.roleAccess.includes(auth.userInfo?.role)
    ) {
      router.push(`${routesAdminObj.dashboard.path}`)
    }
    if (Object.keys(auth.userInfo).length) {
      setLoading(false)
    }
  }, [JSON.stringify(auth.userInfo)])

  useEffect(() => {
    checkIsLogin()
    const lang = localStorage.getItem(storage.language)
    handleSyncLanguage(lang)
  }, [JSON.stringify(router.pathname)])

  if (loading) {
    return (
      <div className="flex justify-center mt-4">
        <Spin></Spin>
      </div>
    )
  }

  return (
    <>
      <Switch>
        {routes.map((route: any) => (
          <AppRoute
            exact={route.exact}
            path={route.path}
            component={route.component}
            key={route.path}
            layout={route.layout}
            ignoredAuthentication={route.ignoredAuthentication}
            roleAccess={route.roleAccess}
          />
        ))}
        <Route
          exact
          path="/"
          render={() => {
            return <Redirect to={routesObj.dashboard.path} />
          }}
        />
        <Route component={PageNotFound} />
      </Switch>
    </>
  )
}

export default Pages
