import { SetStateAction, useEffect, useState } from 'react'
import { Spin } from 'antd'
import type { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface'
import { DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

import BaseTable from '../../components/BaseTable'
import { DeviceServices } from '../../services/DeviceServices'
import { utils } from '../../utils/utils'
import { role } from '../../constants/role'
import { DeviceType } from '../../types/deviceType'
import { deviceTypeI18n, modalType, statusDeviceI18n } from '../../constants/device'
import { routesObj, routesAdminObj } from '../../routes/routes'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { selectDevice } from '../../redux/slices/deviceSlice'
import EditViewModal from '../devices/components/EditAddModal'
import BaseModal from '../../components/BaseModal'
import FormItem from '../request/components/Form'
import { useRouter } from '../../hooks/useRouter'
import DeleteForm from './components/Deleteform'
import { filterScreen } from '../../constants/filterScreen'
import { configuration } from '../../configuration'
import { handlePathName } from '../../redux/slices/deviceFilterSlice'
import { TableParamsType } from '../../types/tableParamsType'
import { useTranslation } from 'react-i18next'

const MyDevices = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const router: any = useRouter()
  const device = useAppSelector((state) => state.device)
  const auth = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()
  const queryParams = new URLSearchParams(window.location.search)
  const pageCurrent = Number(queryParams.get('page') || 1)
  const [currentPage, setCurrentPage] = useState(pageCurrent)
  const deviceFilter = useAppSelector((state) => state.deviceFilter)
  const textSearch = queryParams.get('keywords') || deviceFilter.key_word
  const [listMyDevices, setListMyDevices] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [dataRecord, setDataRecord] = useState({})
  const normalRole = [role.manager.value, role.user.value].includes(
    auth.userInfo.role
  )
  const [isModalVisible, setIsModalVisible] = useState({
    type: '',
    visible: false
  })
  const [isDeleteModalVisible, setDeleteModalVisible] = useState<boolean>()
  const [dataDetail, setDataDetail] = useState<any>({})
  const [isCreateRequestModal, setIsCreateRequestModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [tableParams, setTableParams] = useState<TableParamsType>({
    sortField: 'id',
    pagination: {
      current: 1
    }
  })

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<DeviceType>
  ) => {
    setTableParams({
      sortField: 'id',
      pagination,
      filters,
      ...sorter
    })
  }

  /* eslint-disable @typescript-eslint/dot-notation */
  const getData = async (page = 1, keywords, searchParams: any) => {
    try {
      const fieldParams = tableParams['order'] !== undefined ? tableParams['columnKey'] : tableParams['sortField']
      const orderParams = tableParams['order'] === 'ascend' ? 'asc' : 'desc'
      const sortParams = `${fieldParams} ${orderParams}`

      const response = (await DeviceServices.getListMyDevices({ page, keywords }, sortParams, searchParams)) || []
      setListMyDevices(response.devices)
      setTotalItems(response.meta.total_result)
    } catch (error) {
      utils.handleError(error)
    } finally {
      setLoading(false)
    }
  }
  /* eslint-disable @typescript-eslint/dot-notation */

  const getDevice = async (id: string) => {
    try {
      const res = await DeviceServices.getDetail(id)
      res.device.original_price = utils.formatMoney(parseFloat(res.device.original_price)) || 0
      res.device.remaining_price = utils.formatMoney(parseFloat(res.device.remaining_price)) || 0
      setDataDetail(res.device)
    } catch (error) {
      utils.handleError(error)
    }
  }

  const handleActions = (record: DeviceType, type: string) => {
    dispatch(selectDevice(record))
    switch (type) {
      case modalType.Edit:
        setIsModalVisible({ type: modalType.Edit, visible: true })
        getDevice(record.id)
        break
      case modalType.AddRequest:
        setIsCreateRequestModal(true)
        break
      default:
        break
    }
  }
  const handleAddNewDevice = () => {
    setIsModalVisible({ type: modalType.Add, visible: true })
    setDataDetail({})
  }

  const handleCancel = () => {
    setIsModalVisible({ type: '', visible: false })
  }

  const handleDeleteDevice = (record: SetStateAction<{}>) => {
    setDataRecord(record)
    setDeleteModalVisible(true)
  }

  const columnsMyDevice = [
    {
      title: t('pageTitle.personal.table.id'),
      dataIndex: 'id',
      key: 'id',
      width: 80,
      sorter: true
    },
    {
      title: t('pageTitle.personal.table.deviceName'),
      dataIndex: 'name',
      key: 'name',
      width: 350,
      sorter: true
    },
    {
      title: t('pageTitle.personal.table.assignee'),
      dataIndex: 'assignee_name',
      key: 'assignee.full_name',
      width: 250,
      sorter: true
    },
    {
      title: t('pageTitle.personal.table.status'),
      dataIndex: 'status',
      key: 'status',
      width: 150,
      sorter: true,
      render: (_: any, record: any) => (
        <div className="d-flex">{statusDeviceI18n(record.status)}</div>
      )
    },
    {
      title: t('pageTitle.personal.table.category'),
      dataIndex: 'category',
      key: 'category',
      width: 200,
      sorter: true,
      render: (_: any, record: any) => (
        <div className="d-flex">{deviceTypeI18n(record.category)}</div>
      )
    },
    {
      title: t('pageTitle.personal.table.deviceType'),
      dataIndex: 'device_type',
      key: 'device_type',
      width: 200,
      sorter: true
    },
    {
      title: t('pageTitle.personal.table.purchaseDate'),
      dataIndex: 'purchase_date',
      key: 'purchase_date',
      width: 180,
      sorter: true
    },
    {
      title: t('pageTitle.personal.table.activationDate'),
      dataIndex: 'activation_date',
      key: 'activation_date',
      width: 180,
      sorter: true
    },
    {
      title: t('pageTitle.personal.table.warrantyPeriod'),
      dataIndex: 'warranty_period',
      key: 'warranty_period',
      width: 180,
      sorter: true,
      render: (_: any, record: any) => (
        <div className="d-flex">{utils.monthFormat(record.warranty_period)}</div>
      )
    },
    {
      title: t('pageTitle.personal.table.originalPrice'),
      dataIndex: 'original_price',
      key: 'original_price',
      width: 180,
      sorter: true,
      render: (_: any, record: any) => (
        <div className="d-flex">{utils.moneyFormat(record, record.original_price)}</div>
      )
    },
    {
      title: t('pageTitle.personal.table.allotmentTime'),
      dataIndex: 'allotment_time',
      key: 'allotment_time',
      width: 180,
      sorter: true,
      render: (_: any, record: any) => (
        <div className="d-flex">{utils.monthFormat(record.allotment_time)}</div>
      )
    },
    {
      title: t('pageTitle.personal.table.remainingPrice'),
      dataIndex: 'remaining_price',
      width: 180,
      render: (_: any, record: any) => (
        <div className="d-flex">{utils.moneyFormat(record, record.remaining_price)}</div>
      )
    },
    {
      title: t('pageTitle.personal.table.borrowDate'),
      dataIndex: 'borrow_date',
      key: 'borrow_date',
      width: 180,
      sorter: true
    },
    {
      title: t('pageTitle.personal.table.returnDate'),
      dataIndex: 'return_date',
      key: 'return_date',
      width: 180,
      sorter: true
    },
    {
      title: t('pageTitle.personal.table.actions'),
      width: 200,
      fixed: 'right',
      align: 'center',
      render: (record: any) => {
        return (
          <>
            <EyeOutlined
              className={'text-xl px-2 hover:text-blue-400'}
              onClick={() => {
                history.push(routesObj.deviceDetails.path.replace(':id', record.id), { data: router.history.location.search, from: routesAdminObj.myDevices.path })
              }}
            />
            {!normalRole && (
              <>
                <EditOutlined
                  className="text-xl px-2 hover:text-blue-400"
                  onClick={() => handleActions(record, modalType.Edit)}
                />
                <DeleteOutlined
                  className={`text-xl px-2 hover:text-blue-400 ${record.status === 'deleted' && 'disable-icon'}`}
                  onClick={() =>
                    record.status === 'deleted'
                      ? null
                      : handleDeleteDevice(record)
                  }
                />
              </>
            )}
            <PlusOutlined
              className={`text-xl px-2 hover:text-blue-400 ${record.status === 'deleted' && 'disable-icon'}`}
              onClick={() =>
                record.status === 'deleted'
                  ? null
                  : handleActions(record, modalType.AddRequest)
              }
            />
          </>
        )
      }
    }
  ]

  const columnsFilter = columnsMyDevice.filter(item => {
    if (auth.userInfo.role === 'user') {
      return !['original_price', 'remaining_price', 'allotment_time'].includes(item.dataIndex)
    }
    return true
  })

  const searchParams = {
    category: deviceFilter.category,
    status: deviceFilter.status,
    min_remaining_price: deviceFilter.remaining_price[0],
    max_remaining_price: deviceFilter.remaining_price[1],
    start_purchase_date: deviceFilter.purchase_date[0],
    end_purchase_date: deviceFilter.purchase_date[1],
    start_borrow_date: deviceFilter.borrow_date[0],
    end_borrow_date: deviceFilter.borrow_date[1],
    start_return_date: deviceFilter.return_date[0],
    end_return_date: deviceFilter.return_date[1]
  }
  const handleFilter = () => {
    history.push({
      pathname: history.location.pathname,
      search: `?${new URLSearchParams({ ...router.query, page: '1' }).toString()}`
    })
  }
  useEffect(() => {
    getData(pageCurrent, queryParams.get('keywords'), searchParams)
    setCurrentPage(Number(router.query.page || 1))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    /* eslint-disable react-hooks/exhaustive-deps */
    JSON.stringify(tableParams),
    JSON.stringify(deviceFilter.category),
    JSON.stringify(deviceFilter.status),
    JSON.stringify(deviceFilter.remaining_price),
    JSON.stringify(deviceFilter.purchase_date),
    JSON.stringify(deviceFilter.borrow_date),
    JSON.stringify(deviceFilter.return_date),
    JSON.stringify(router.query)
    /* eslint-enable react-hooks/exhaustive-deps */
  ])
  useEffect(() => {
    document.title = `Devices management - ${t('pageTitle.myDevices')}`
    dispatch(handlePathName(window.location.pathname))
  }, [])

  if (loading) {
    return (
      <div className="flex justify-center mt-4">
        <Spin></Spin>
      </div>
    )
  }

  return (
    <div>
      <EditViewModal
        dataDetail={dataDetail}
        isModalVisible={isModalVisible.visible}
        type={isModalVisible.type}
        handleCancel={handleCancel}
        getData={async () => await getData(pageCurrent, textSearch, searchParams)}
      />
      {isDeleteModalVisible && (
        <BaseModal
          visible={isDeleteModalVisible}
          handleCancel={() => setDeleteModalVisible(false)}
          content={
            <DeleteForm
              dataRecord={dataRecord}
              forceRequestList={() => {
                setDeleteModalVisible(false)
                getData(pageCurrent, textSearch, searchParams)
              }}
              handleCancelModal={() => {
                setDeleteModalVisible(false)
              }}
            />
          }
          title={'Delete Device'}
          width={350}
          footer={null}
          bodyStyle={{ padding: '5px 24px 14px' }}
        />
      )}
      {isCreateRequestModal && (
        <BaseModal
          visible={isCreateRequestModal}
          handleCancel={() => setIsCreateRequestModal(false)}
          content={
            <FormItem
              isDevice
              dataDevice={device.selectedDevice}
              forceRefeshTable={() => {
                setIsCreateRequestModal(false)
                router.push(routesAdminObj.requests.path)
              }}
            />
          }
          title={'Create request'}
          width={1000}
          footer={null}
        />
      )}
      <BaseTable
        columns={columnsFilter}
        dataSource={listMyDevices}
        total={totalItems}
        pageSize={configuration.pageSize}
        handleAddNewDevice={normalRole ? false : handleAddNewDevice}
        callbackFilter={handleFilter}
        filterScreen={filterScreen.devices}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        handleTableChange={handleTableChange}
      />
    </div>
  )
}

export default MyDevices
