import AdminLayout from '../components/Layouts/Admin'
import AuthLayout from '../components/Layouts/Auth'
import { role } from '../constants/role'
import Dashboard from '../pages/dashboard'
import Devices from '../pages/devices'
import DeviceDetails from '../pages/devices/details'
import Login from '../pages/login'
import MyDevices from '../pages/myDevice'
import Request from '../pages/request'
import Details from '../pages/request/details'
import Users from '../pages/users'
import Groups from '../pages/groups'
import MyGroup from '../pages/myGroup'
import UserDetails from '../pages/users/Component/UserDetails'
import HistoryDeviceDetails from '../pages/devices/details/historyDevices/details'
import Tenants from '../pages/tenants'
import CallBack from '../pages/callback'
import i18n from '../lang/i18n'
import DeviceByGroups from '../pages/groups/device_by_groups'

interface IRoutesAuthObj {
  login: {
    exact?: boolean
    path: string
    layout: any
    component: any
    ignoredAuthentication: boolean
    roleAccess?: any
  }
  callBack: {
    title?: string
    exact?: boolean
    path: string
    layout: any
    component: any
    ignoredAuthentication: boolean
    roleAccess?: any
  }
}

interface IRoute {
  title: string
  exact?: boolean
  path: string
  layout: any
  component: any
  roleAccess?: string[]
}
interface IRoutesAdminObj {
  dashboard: IRoute
  users: IRoute
  myDevices: IRoute
  devices: IRoute
  deviceDetails: IRoute
  requests: IRoute
  requestDetails: IRoute
  userProfile: IRoute
  deviceHistoryDetails: IRoute
  tenants: IRoute
  groups: IRoute
  myGroup: IRoute
  groupDevices: IRoute
}

interface IRoutesObj extends IRoutesAuthObj, IRoutesAdminObj {}
export const routesAuthObj: IRoutesAuthObj = {
  login: {
    path: '/login',
    layout: AuthLayout,
    component: Login,
    ignoredAuthentication: true
  },
  callBack: {
    title: i18n.t('pageTitle.callback'),
    path: '/callback',
    layout: AuthLayout,
    component: CallBack,
    ignoredAuthentication: true
  }
}
export const routesAdminObj: IRoutesAdminObj = {
  dashboard: {
    exact: true,
    title: i18n.t('pageTitle.dashboard'),
    path: '/',
    layout: AdminLayout,
    component: Dashboard,
    roleAccess: [role.admin.value, role.manager.value, role.operator.value]
  },

  devices: {
    exact: true,
    title: i18n.t('pageTitle.devices'),
    path: '/devices',
    layout: AdminLayout,
    component: Devices,
    roleAccess: [role.admin.value, role.manager.value, role.operator.value]
  },
  deviceDetails: {
    exact: true,
    title: i18n.t('pageTitle.devices'),
    path: '/devices/:id',
    layout: AdminLayout,
    component: DeviceDetails,
    roleAccess: [role.admin.value, role.manager.value, role.operator.value]
  },
  myDevices: {
    title: i18n.t('pageTitle.myDevices'),
    path: '/my-device',
    layout: AdminLayout,
    component: MyDevices
  },
  requests: {
    title: i18n.t('pageTitle.request'),
    exact: true,
    path: '/requests',
    layout: AdminLayout,
    component: Request
  },
  requestDetails: {
    exact: true,
    title: i18n.t('pageTitle.requestsDetails'),
    path: '/requests/:id',
    layout: AdminLayout,
    component: Details
  },
  users: {
    title: i18n.t('pageTitle.users'),
    path: '/users',
    layout: AdminLayout,
    component: Users,
    roleAccess: [role.admin.value]
  },
  userProfile: {
    title: i18n.t('pageTitle.userProfile'),
    path: '/user-profile',
    layout: AdminLayout,
    component: UserDetails
  },
  deviceHistoryDetails: {
    title: i18n.t('pageTitle.deviceHistory'),
    path: '/device-histories/:id',
    layout: AdminLayout,
    component: HistoryDeviceDetails,
    roleAccess: [role.admin.value, role.manager.value, role.operator.value]
  },
  tenants: {
    title: i18n.t('pageTitle.tenants'),
    path: '/tenants',
    layout: AdminLayout,
    component: Tenants,
    roleAccess: [role.admin.value]
  },
  groupDevices: {
    exact: true,
    title: i18n.t('pageTitle.deviceGroups'),
    path: '/groups/:id/devices',
    layout: AdminLayout,
    component: DeviceByGroups,
    roleAccess: [role.admin.value]
  },
  groups: {
    title: i18n.t('pageTitle.groups'),
    path: '/groups',
    layout: AdminLayout,
    component: Groups,
    roleAccess: [role.admin.value]
  },
  myGroup: {
    title: i18n.t('pageTitle.myGroup'),
    path: '/my-group',
    layout: AdminLayout,
    component: MyGroup
  }
}
export const routesObj: any = {
  ...routesAuthObj,
  ...routesAdminObj
}
export const routes = Object.keys(routesObj).map(
  (item) => routesObj[item as keyof IRoutesObj]
)

export const pageNotFound = '/404'
