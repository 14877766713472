import { createSlice } from '@reduxjs/toolkit'
import { UserType } from '../../types/userType'

const initialState: UserType = {
  id: 0,
  email: '',
  full_name: '',
  role: ''
}

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
  }
})

export default userSlice.reducer
