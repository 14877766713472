import { TenantPostType, TenantPatchType } from '../types/tenantType'
import { apiConstants } from './ApiConstants'
import { ApiServices } from './ApiServices'

export const TenantServices = {
  async getListTenants (page: number, keywords: string, allowedDomains?: string, sortParams?: any) {
    return await ApiServices().get(apiConstants.TENANTS, {
      params: {
        page,
        keywords,
        allowed_domains: allowedDomains,
        sort_order: sortParams
      }
    })
  },
  async createNewTenant (data: TenantPostType) {
    return await ApiServices().post(apiConstants.TENANTS, data)
  },
  async updateTenant (id: number, data: TenantPatchType) {
    return await ApiServices().patch(`${apiConstants.TENANTS}/${id}`, data)
  },
  async getDetail (id: number | string) {
    return await ApiServices().get(`${apiConstants.TENANTS}/${id}`)
  },
  async deleteTenant (id: number | string) {
    return await ApiServices().delete(`${apiConstants.TENANTS}/${id}`)
  }
}
