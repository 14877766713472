import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export default function Dashboard () {
  const { t } = useTranslation()
  useEffect(() => {
    document.title = `Devices management - ${t('dashboard.title')}`
  }, [])

  return <strong>{t('dashboard.title')}</strong>
}
