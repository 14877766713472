import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  allowedDomains: ''
}

const tenantFilterSlice = createSlice({
  name: 'tenantFilterSlice',
  initialState,
  reducers: {
    handleChangeAllowedDomain: (state, action: PayloadAction<string>) => {
      state.allowedDomains = action.payload
    },
    resetFilterTenant: (state) => {
      state.allowedDomains = ''
    }
  }
})

export const { handleChangeAllowedDomain, resetFilterTenant } = tenantFilterSlice.actions
export default tenantFilterSlice.reducer
