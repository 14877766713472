import { Col, Row } from 'antd'
import { useEffect } from 'react'
import { AuthOtherService } from '../../services/AuthOtherService'
import { utils } from '../../utils/utils'
import { AuthServices } from '../../services/AuthServices'
import { storage } from '../../constants/storage'
import { routesObj } from '../../routes/routes'

export default function CallBack () {
  const queryParams = new URLSearchParams(window.location.search)
  const code = queryParams.get('code')

  const loginComplete = async (dataUsers = {}) => {
    const dataSend = {
      users: dataUsers,
      code
    }
    try {
      const res = await AuthServices.loginWithID(dataSend)
      const { access_token: accessToken, refresh_token: refreshToken, id_token: idToken } = res.data.data
      utils.setStorage(storage.ACCESS_TOKEN, accessToken)
      utils.setStorage(storage.REFRESH_TOKEN, refreshToken)
      utils.setStorage(storage.ID_TOKEN, idToken)
      window.location.href = routesObj.dashboard.path
    } catch (error) {
      utils.handleError(error)
    }
  }

  const oauth2Me = async () => {
    try {
      const res = await AuthOtherService.loginOauth2(code)
      const dataUsers = res.data.users
      utils.setStorage(storage.ID_USER, dataUsers.uid)
      loginComplete(dataUsers)
    } catch (error) {
      utils.handleError(error)
    }
  }

  useEffect(() => {
    oauth2Me()
  }, [])

  return (
    <div className="flex flex-col h-full justify-center w-full text-center">
      <Row justify="center" className="items-center" style={{ height: '100vh' }}>
        <Col xs={20} sm={20} md={10} lg={7} className="min-w-max">
            Processing Authentication...
        </Col>
      </Row>
    </div>
  )
}
