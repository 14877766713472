import { Button, Form, Select } from 'antd'
import { useEffect, useState } from 'react'

import { roleList } from '../../../../constants/role'
import { UserServices } from '../../../../services/UserServices'
import { utils } from '../../../../utils/utils'
import { useTranslation } from 'react-i18next'

interface ModalProps {
  dataRecord: any
  forceReGetListUser?: any
}

const EditRole = ({ dataRecord, forceReGetListUser }: ModalProps) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [disabled, setDisabled] = useState(true)
  const handleFinish = async (values: any) => {
    try {
      await UserServices.updateUser(dataRecord.id, values.role)
      utils.handleSuccessNotify(t('message.deleteFormUser.success'))
      forceReGetListUser && forceReGetListUser()
    } catch (error) {
      utils.handleError(error)
    }
  }
  useEffect(() => {
    if (dataRecord.role) {
      form.setFieldValue('role', dataRecord.role)
    }
  }, [dataRecord.role])
  return (
    <>
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <>
          <Form.Item label={t('message.editRoleUser.roles')} name="role">
            <Select
              options={roleList}
              onChange={() => setDisabled(false)}
              getPopupContainer={trigger => trigger.parentNode}
            />
          </Form.Item>
          <div className="d-flex justify-content-end">
            <Button
              disabled={disabled}
              type="primary"
              htmlType="submit"
              className="ml-auto"
            >
              {t('update')}
            </Button>
          </div>
        </>
      </Form>
    </>
  )
}

export default EditRole
