import { InboxOutlined } from '@ant-design/icons'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import {
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Upload,
  InputNumber
} from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import moment from 'moment'
import { useEffect, useState } from 'react'
import SelectSearchData from '../../../components/SelectSearchData'
import { configuration } from '../../../configuration'
import { dateFormat } from '../../../constants/common'
import { COMMON_CURRENCY, deviceTypes, modalType } from '../../../constants/device'
import { role } from '../../../constants/role'
import { useAppSelector } from '../../../hooks'
import { DeviceServices } from '../../../services/DeviceServices'
import { DeviceType, StatusType } from '../../../types/deviceType'
import { UserType } from '../../../types/userType'
import { utils } from '../../../utils/utils'
import './device_form.scss'
import { useTranslation } from 'react-i18next'

interface DeviceFormProps {
  form: FormInstance
  handleFinish: (values: DeviceType) => void
  type: string
  dataDetail: any
  isCreate: any
  photoIds: any
  setPhotoIds: any
}

const MAX_MIN_VALUE = {
  warranty_period: {
    max: 1000,
    min: 0
  },
  allotment_time: {
    max: 1000,
    min: 0
  }
}

const DeviceForm = ({
  form,
  handleFinish,
  type,
  dataDetail,
  isCreate,
  photoIds,
  setPhotoIds
}: DeviceFormProps) => {
  const { t } = useTranslation()
  const { Option } = Select
  const [options, setOptions] = useState<DefaultOptionType[]>([])
  const [status, setStatus] = useState<StatusType[]>([])
  const [editorData, setEditorData] = useState<string>('')
  const device = useAppSelector((state) => state.device)
  const auth = useAppSelector((state) => state.auth)
  const [isValid, setIsValid] = useState<boolean>(true)
  const [currency, setCurrency] = useState<string>('')
  const currencyRemaining = dataDetail?.original_price_unit?.toUpperCase()
  const fetchUser = async (value: string) => {
    try {
      const res = await DeviceServices.getListUsersForSelect({
        page: 1,
        limit: configuration.pageSizeSearch,
        keywords: value
      })
      const options = res.users.map((user: UserType) => ({
        value: user.id.toString(),
        label: user.full_name
      }))
      options.sort((a, b) => a.label.localeCompare(b.label))
      setOptions(options)
    } catch (error) { }
  }

  const getStatus = async () => {
    try {
      const res = await DeviceServices.getListStatus()
      setStatus(res)
    } catch (error) {
      utils.handleError(error)
    }
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  useEffect(() => {
    setEditorData(dataDetail.description || '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dataDetail)])

  useEffect(() => {
    fetchUser('')
    getStatus()
  }, [])

  const requiredRule = {
    required: true,
    message: t('message.requiredRule')
  }
  const deviceDeleted = !!(device.selectedDevice.status === 'deleted' && !isCreate)

  const optionsStatus = () => {
    const i18nLabels = {
      available: t('devices.statusDevice.available'),
      broken: t('devices.statusDevice.broken'),
      using: t('devices.statusDevice.using'),
      warranty: t('devices.statusDevice.warranty'),
      preparing: t('devices.statusDevice.preparing'),
      deleted: t('devices.statusDevice.deleted')
    }
    let options = status
    switch (dataDetail.status) {
      case 'using':
        options = status.filter(item => ['available', 'using', 'broken', 'warranty', 'deleted'].includes(item.value))
        break
      case 'broken':
        options = status.filter(item => ['broken', 'warranty', 'deleted'].includes(item.value))
        break
      case 'deleted':
        options = status.filter(item => ['deleted'].includes(item.value))
        break
      case 'preparing':
        options = status.filter(item => ['using', 'broken', 'warranty', 'deleted', 'preparing'].includes(item.value))
        break
      default:
        break
    }
    options.forEach(item => {
      item.label = i18nLabels[item.value] || item.value
    })
    return options
  }
  const priceType = (
    <Form.Item
      name='original_price_unit'
      style={{ margin: '0 0 -2px 0' }}
    >
      <Select
        disabled={deviceDeleted}
        value={currency}
        className='select-after'
        onChange={(value) => { setCurrency(value) }}
        getPopupContainer={trigger => trigger.parentNode}
      >
        {COMMON_CURRENCY.map((item) => (
          <Option key={item.value} value={item.value}>{item.label}</Option>
        ))}
      </Select>
    </Form.Item>
  )
  ClassicEditor.defaultConfig = {
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        // 'insertTable',
        '|',
        // 'imageUpload',
        '|',
        // 'blockQuote',
        '|',
        'undo',
        'redo'
      ]
    },
    image: {
      toolbar: [
        'imageStyle:full',
        'imageStyle:side',
        '|',
        'imageTextAlternative'
      ]
    }
    // table: {
    //   contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    // },
    // language: 'en'
  }

  const checkOriginalPrice = async () => {
    const originalPrice = form.getFieldValue('original_price')
    if (!originalPrice) return

    const price = parseFloat(originalPrice.replaceAll(',', ''))
    const strPrice = price.toString().replaceAll('.', '')
    if (strPrice.length > 10) {
      return await Promise.reject(t('message.deviceForm.requiredOriginalPrice.maximum'))
    }

    return await Promise.resolve()
  }

  const checkDate = async () => {
    setIsValid(true)
    const purchaseDate = form.getFieldValue('purchase_date')
    const activationDate = form.getFieldValue('activation_date')
    const borrowDate = form.getFieldValue('borrow_date')
    const returnDate = form.getFieldValue('return_date')
    if (activationDate) {
      if (!purchaseDate || new Date(moment(purchaseDate).format(dateFormat)) > new Date(moment(activationDate).format(dateFormat))) {
        form.setFields([{ name: 'activation_date', errors: [t('message.deviceForm.requiredOriginalPrice.activationPurchase')], validating: false }])
        setIsValid(false)
      } else {
        form.setFields([{ name: 'activation_date', errors: [''], validating: true }])
      }
    }
    if (borrowDate) {
      if (!purchaseDate || new Date(moment(purchaseDate).format(dateFormat)) > new Date(moment(borrowDate).format(dateFormat))) {
        form.setFields([{ name: 'borrow_date', errors: [t('message.deviceForm.requiredOriginalPrice.borrowPurchase')], validating: false }])
        setIsValid(false)
      } else {
        form.setFields([{ name: 'borrow_date', errors: [''], validating: true }])
      }
    }
    if (returnDate) {
      if (!purchaseDate || new Date(moment(purchaseDate).format(dateFormat)) > new Date(moment(returnDate).format(dateFormat))) {
        form.setFields([{ name: 'return_date', errors: [t('message.deviceForm.requiredOriginalPrice.returnPurchase')], validating: false }])
        setIsValid(false)
      } else {
        form.setFields([{ name: 'return_date', errors: [''], validating: true }])
      }
    }
    if (borrowDate) {
      if (returnDate && new Date(moment(borrowDate).format(dateFormat)) > new Date(moment(returnDate).format(dateFormat))) {
        form.setFields([{ name: 'return_date', errors: [t('message.deviceForm.requiredOriginalPrice.returnBorrow')], validating: false }])
        setIsValid(false)
      } else {
        form.setFields([{ name: 'return_date', errors: [''], validating: true }])
      }
    }
  }

  const checkMaxMinValue = async (rule: any, value: any) => {
    const excluded = [null, undefined, '']
    if (excluded.includes(value)) return

    const field = rule.field.replace('_', ' ')
    const fieldName = `${field.charAt(0).toUpperCase()}${field.slice(1)}`
    const max = MAX_MIN_VALUE[rule.field].max
    const min = MAX_MIN_VALUE[rule.field].min

    if (value >= max) {
      return await Promise.reject(`${fieldName} ${t('message.deviceForm.maxMinValue.lessThan')} ${max}`)
    }

    if (value <= min) {
      return await Promise.reject(`${fieldName} ${t('message.deviceForm.maxMinValue.greaterThan')} ${min}`)
    }

    return await Promise.resolve()
  }

  const deletePhotos = (photo: any) => {
    if (!photo.id) return

    setPhotoIds([...photoIds, photo.id])
  }

  return (
    <Form
      scrollToFirstError
      form={form}
      layout='vertical'
      onFinish={(values) => {
        if (!isValid) return
        handleFinish({ ...values, description: editorData, original_price: parseFloat(values.original_price.replaceAll(',', '')) })
      }}
    >
      <Row gutter={20}>
        <Col span={8}>
          <Form.Item
            label={t('pageTitle.personal.table.deviceName')}
            name='name'
            required
            rules={[requiredRule, { max: 250, message: t('message.deviceForm.requiredDeviceName') }]}
          >
            <Input disabled={deviceDeleted} />
          </Form.Item>
          <Form.Item
            label={t('pageTitle.personal.table.status')}
            name='status'
            required
            initialValue='available'
            rules={[requiredRule]}
          >
            <Select disabled={deviceDeleted} getPopupContainer={trigger => trigger.parentNode}>
              {optionsStatus()?.map((ele, index) => (
                <Select.Option value={ele.value} key={index}>
                  {ele.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('pageTitle.personal.table.purchaseDate')}
            name='purchase_date'
            required
            rules={[requiredRule, { validator: checkDate }]}
          >
            <DatePicker
              placeholder={t('datepickerPlaceholder')}
              format={dateFormat}
              className="w-full"
              disabled={deviceDeleted}
              getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            />
          </Form.Item>
          <Form.Item
            label={t('pageTitle.personal.table.originalPrice')}
            name='original_price'
            required
            rules={[requiredRule, { validator: checkOriginalPrice }]}
            className={deviceDeleted ? 'addon-disabled price-device' : 'price-device'}
            normalize={(value) => {
              if (value.includes('.')) {
                const valueConvert = value.replaceAll(',', '')
                const lenght = value.length - value.indexOf('.') - 1
                if (lenght === 0) {
                  return value
                } else {
                  return isNaN(Number(valueConvert.replaceAll(',', ''))) ? '' : utils.formatMoneyDecimal(Number(valueConvert.replaceAll(',', '')), lenght)
                }
              } else {
                const valueTemp = parseFloat(value.replaceAll(',', ''))
                return isNaN(valueTemp) ? '' : utils.formatMoneyNonDecimal(valueTemp, 0)
              }
            }}
          >
            <Input addonAfter={priceType} disabled={deviceDeleted} />
          </Form.Item>
          {auth.userInfo.role !== role.user.value && (
            <Form.Item label={t('pageTitle.personal.table.managerNotes')} name='manager_notes'>
              <Input.TextArea autoSize={{ minRows: 5, maxRows: 5 }} />
            </Form.Item>
          )}
        </Col>
        <Col span={8}>
          <Form.Item
            label={t('pageTitle.personal.table.deviceType')}
            name='device_type'
            rules={[{ max: 20, message: t('message.deviceForm.requiredDeviceType') }]}
          >
            <Input disabled={deviceDeleted} />
          </Form.Item>
          <Form.Item
            label={t('pageTitle.personal.table.category')}
            name='category'
            required
            rules={[requiredRule]}
          >
            <Select options={deviceTypes} disabled={deviceDeleted} getPopupContainer={trigger => trigger.parentNode} />
          </Form.Item>
          <Form.Item label={t('pageTitle.personal.table.invoiceNumber')} name='invoice_number'>
            <Input disabled={deviceDeleted} />
          </Form.Item>
          <Form.Item
            label={t('pageTitle.personal.table.activationDate')}
            name="activation_date"
            rules={[{ validator: checkDate }]}
          >
            <DatePicker
              placeholder={t('datepickerPlaceholder')}
              format={dateFormat}
              className='w-full'
              disabled={deviceDeleted}
              getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            />
          </Form.Item>
          <Form.Item
            label={t('pageTitle.personal.table.allotmentTime')}
            name='allotment_time'
            required
            rules={[requiredRule, { validator: checkMaxMinValue }]}
          >
            <InputNumber addonAfter={t('month')} disabled={deviceDeleted} style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            label={t('pageTitle.personal.table.borrowDate')}
            name="borrow_date"
            rules={[{ validator: checkDate }]}
          >
            <DatePicker
              placeholder={t('datepickerPlaceholder')}
              format={dateFormat}
              className='w-full'
              disabled={deviceDeleted}
              getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            />
          </Form.Item>
          {type === modalType.Edit && (
            <Form.Item
              label={t('pageTitle.personal.table.deviceCode')}
              name='code'
              required
              rules={[requiredRule]}
            >
              <Input disabled />
            </Form.Item>
          )}
        </Col>
        <Col span={8}>
          <Form.Item
            label={t('pageTitle.personal.table.serialNumber')}
            name='serial_number'
            required
            rules={[requiredRule]}
          >
            <Input disabled={deviceDeleted} />
          </Form.Item>
          <Form.Item
            label={t('pageTitle.personal.table.assignee')}
            name='assignee_id'
            required
            rules={[requiredRule]}
          >
            <SelectSearchData options={options} fetchData={fetchUser} disabled={deviceDeleted} />
          </Form.Item>
          <Form.Item label={t('pageTitle.personal.table.supplier')} name='supplier'>
            <Input disabled={deviceDeleted} />
          </Form.Item>
          <Form.Item
            label={t('pageTitle.personal.table.warrantyPeriod')}
            name='warranty_period'
            className={deviceDeleted ? 'addon-disabled' : ''}
            rules={[{ validator: checkMaxMinValue }]}
          >
            <InputNumber addonAfter={t('month')} disabled={deviceDeleted} style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item label={t('pageTitle.personal.table.remainingPrice')} name='remaining_price'>
            <Input addonAfter={currencyRemaining} disabled />
            {/* <Input disabled /> */}
          </Form.Item>
          <Form.Item
            label={t('pageTitle.personal.table.returnDate')}
            name="return_date"
            rules={[{ validator: checkDate }]}
          >
            <DatePicker format={dateFormat}
              placeholder={t('datepickerPlaceholder')}
              className='w-full'
              disabled={deviceDeleted}
              getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label='Images'
        name='photos'
        valuePropName='fileList'
        getValueFromEvent={normFile}
        noStyle
      >
        <Upload.Dragger
          name='files'
          accept='image/png, image/gif, image/jpeg, image/jpg'
          action={undefined}
          multiple={true}
          beforeUpload={() => {
            return false
          }}
          onRemove={(photo) => {
            deletePhotos(photo)
          }}
        >
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <p className='ant-upload-text'>
            {t('pageTitle.personal.upload.textContent')}
          </p>
          <p className='ant-upload-hint'>
            {t('pageTitle.personal.upload.hintUpload')}
            <br />
            {t('pageTitle.personal.upload.typeUpload')}
          </p>
        </Upload.Dragger>
      </Form.Item>
      <Form.Item
        label={t('pageTitle.personal.table.description')}
        name='description'
        required
        rules={[requiredRule]}
      >
        <CKEditor
          editor={ClassicEditor}
          data={editorData}
          onChange={(_: any, editor: any) => {
            if (!editor.getData()) form.setFieldValue('description', null)
            setEditorData(editor.getData())
          }}
        />
      </Form.Item>
    </Form>
  )
}

export default DeviceForm
