import React from 'react'
import { Button, Result } from 'antd'
import { routesObj } from '../../routes/routes'

type BackActionType = () => void

const backAction: BackActionType = () => {
  window.location.href = routesObj.dashboard.path
}

const PageNotFound = () => (
  <Result
    status="404"
    title="PageNotFound"
    subTitle="Sorry, the page you visited does not exist."
    extra={<Button onClick={backAction} type="primary">Back Home</Button>}
  />
)

export default PageNotFound
