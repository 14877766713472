import { CaretLeftOutlined } from '@ant-design/icons'
import { Button, Card, Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

interface IContentDetails {
  data?: any
  title?: string
}

export default function ContentDetails (props: IContentDetails) {
  const { t } = useTranslation()
  const { data, title } = props
  const history = useHistory()
  const backList = () => {
    history.goBack()
  }

  return (
    <>
      <Button
        onClick={backList}
        icon={<CaretLeftOutlined />}
        type="primary"
        className="mb-6"
      >
        {t('back')}
      </Button>
      <Card bodyStyle={{}}>
        <div className="text-3xl">{title}</div>
        <Row className="mt-6">
          {data.map((ele: any, index: number) => (
            <Col span={12} className="p-2" key={index}>
              <span className="font-bold mr-1">{ele.label}:</span>
              <span className={ele.label === 'Role' ? 'capitalize' : ''}>{ele.value}</span>
            </Col>
          ))}
        </Row>
      </Card>
    </>
  )
}
