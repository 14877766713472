
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RequestsFilterType, FilterPayload } from '../../types/requestType'

const initialState: RequestsFilterType = {
  tracker: null,
  priority: null,
  status: 'open',
  start_date: '',
  due_date: '',
  url: ''
}

const requestFilterSlice = createSlice({
  name: 'requestFilterSlice',
  initialState,
  reducers: {
    handleSelect: (state, action: PayloadAction<FilterPayload>) => {
      switch (action.payload.type) {
        case 'tracker':
          if (state.tracker !== action.payload.value) {
            state.tracker = action.payload.value
          }
          break
        case 'priority':
          if (state.priority !== action.payload.value) {
            state.priority = action.payload.value
          }
          break
        case 'status':
          if (state.status !== action.payload.value) {
            state.status = action.payload.value
          }
          break
        case 'start_date':
          if (state.start_date !== action.payload.value) {
            state.start_date = action.payload.value
          }
          break
        case 'due_date':
          if (state.due_date !== action.payload.value) {
            state.due_date = action.payload.value
          }
          break
        default:
          break
      }
    },
    resetFilterRequest: (state) => {
      state.tracker = null
      state.priority = null
      state.status = 'open'
      state.start_date = ''
      state.due_date = ''
      state.url = ''
    },
    handleUrlRequest: (state, action: PayloadAction<string>) => {
      state.url = action.payload.includes('?') ? action.payload.slice(action.payload.indexOf('?'), action.payload.length).replaceAll('amp;', '') : ''
    }
  }
})

export const { handleSelect, resetFilterRequest, handleUrlRequest } = requestFilterSlice.actions
export default requestFilterSlice.reducer
