import { DeviceFilterType, CurrencyType } from '../types/deviceType'
import { utils } from '../utils/utils'
import i18n from '../lang/i18n'

export const modalType = {
  Add: i18n.t('devices.createDevice'),
  View: 'View',
  Edit: i18n.t('devices.updateDevice'),
  Delete: 'Delete',
  AddRequest: 'AddRequest'
}

export const deviceTypes = [
  { value: 'company', label: i18n.t('devices.deviceTypes.company') },
  { value: 'personal', label: i18n.t('devices.deviceTypes.personal') }
]
export const statusDevice = [
  { value: 'using', label: i18n.t('devices.statusDevice.using') },
  { value: 'broken', label: i18n.t('devices.statusDevice.broken') },
  { value: 'available', label: i18n.t('devices.statusDevice.available') },
  { value: 'warranty', label: i18n.t('devices.statusDevice.warranty') },
  { value: 'deleted', label: i18n.t('devices.statusDevice.deleted') },
  { value: 'preparing', label: i18n.t('devices.statusDevice.preparing') }
]

export const titleDevices = '/device'

export const COMMON_DEVICE_FILTER: DeviceFilterType = {
  device_type: '',
  status: '',
  category: '',
  purchase_date: '',
  remaining_price: 0,
  borrow_date: '',
  return_date: '',
  url: '',
  path_name: '',
  key_word: ''
}

export const deviceTypeI18n = (value: string) => {
  return deviceTypes.find((type) => type.value === value)?.label || ''
}

export const statusDeviceI18n = (value: string) => {
  return statusDevice.find((status) => status.value === value)?.label || ''
}

export const currency: CurrencyType = {
  VND: {
    value: 'vnd',
    label: 'VND'
  },
  JPY: {
    value: 'jpy',
    label: 'JPY'
  },
  USD: {
    value: 'usd',
    label: 'USD'
  }
}

export const COMMON_CURRENCY: any = utils.convertDataObjectToArray(
  currency
)
