import React, { useState } from 'react'
import { AppstoreOutlined } from '@ant-design/icons'
import { utils } from '../../utils/utils'
import { storage } from '../../constants/storage'
import { ApiListService } from '../../services/ApiListService'
import { Card, Col, Dropdown, Image, Row, Space } from 'antd'
import './index.scss'
import { apiConstants } from '../../services/ApiConstants'

const ListService = () => {
  const [services, setServices] = useState<any>([])
  const [servicesExternal, setServicesExternal] = useState<any>([])
  const [domain, setDomain] = useState<any>('')
  const idToken: any = utils.getStorage(storage.ID_TOKEN) || ''
  const userId: any = utils.getStorage(storage.ID_USER) || ''
  const serviceId: any = process.env.REACT_APP_SERVICE_ID
  const urlVID: any = process.env.REACT_APP_BASE_URL_REQUEST_VID
  const clientId: any = process.env.REACT_APP_CLIENT_ID
  const endPoint: any = apiConstants.loginOauth2

  const getListService = async () => {
    try {
      const res = await ApiListService.listService(urlVID, idToken, serviceId, userId)
      setServices(res?.data.services)
      setServicesExternal(res?.data.other_services)
      setDomain(res?.data?.domain_origin || '')
    } catch (error: any) {
      utils.handleError(error)
    }
  }

  const redirectUrl = (index: number, item: any) => {
    if (index === 0) {
      return item.domain ? item.domain : 'about:blank'
    }
    return item.public_uid
      ? `${urlVID}/${endPoint}?service_id=${item.public_uid}&client_id=${clientId}&response_type=code`
      : 'about:blank'
  }

  const renderService = (item, index, domain, external = false) => (
    <Col key={item} span={8} className='mb-4'>
      <a
        href={external ? item.domain || 'about:blank' : redirectUrl(index, item)}
        className='hover:bg-[#e2e8f0]'
        target='_blank'
        rel='noreferrer'
      >
        <div className='flex flex-col justify-center items-center hover:bg-[#e2e8f0] rounded-lg py-2 max-w-[90px] m-auto'>
          <Image
            src={`${domain}${item.logo_url}`}
            alt='logo'
            height={50}
            width={50}
            className='!align-baseline'
            preview={false}
          />
          <div className='text-sm text-black mt-2 truncate max-w-[90px]'>{item.name}</div>
        </div>
      </a>
    </Col>
  )
  const renderListService = (
    <div className="header-service">
      {(services.length || servicesExternal.length)
        ? (
            <Card className='w-[350px] bg-slate-200'>
              <Row className='bg-white mb-1.5 rounded'>
                {services?.map((item, index) => renderService(item, index, domain))}
              </Row>
              <Row className='bg-white rounded pt-4'>
                {servicesExternal?.map((item) => renderService(item, null, domain, true))}
              </Row>
            </Card>
          )
        : null}
    </div>
  )

  return (
    <>
      <Dropdown
        className="text-white list-service__common"
        overlay={renderListService}
        placement="bottomLeft"
        destroyPopupOnHide={true}
        trigger={['click']}
      >
        <a onClick={async () => await getListService()}>
          <Space>
            <div className='truncateText max-w-[100px] text-center'>
              <AppstoreOutlined style={{ fontSize: '20px' }} />
            </div>
          </Space>
        </a>
      </Dropdown>
    </>
  )
}

export default ListService
