const languageObj = {
  en: {
    label: (
      <span>
        <img src='/usa.png' alt='UK' className='ml-3 mr-4 w-6' />
        English
      </span>
    ),
    key: 'en',
    text: 'English'
  },
  ja: {
    label: (
      <span>
        <img src='/japan.png' alt='JP' className='ml-3 mr-4 w-6' />
        日本語
      </span>
    ),
    key: 'ja',
    text: '日本語'
  }
}

export { languageObj }
