import { useEffect, useState } from 'react'
import { UserServices } from '../../../../services/UserServices'
import ContentDetails from '../ContentDetails'
import { useTranslation } from 'react-i18next'

const UserDetails = () => {
  const [data, setData] = useState<any>()
  const { t } = useTranslation()

  const getData = async () => {
    try {
      const res = (await UserServices.getDetail()).data || []
      setData(res.user)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    document.title = `Devices management - ${t('pageTitle.userProfile')}`
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const info = [
    { label: 'Id', value: data?.id },
    { label: t('userForm.email'), value: data?.email },
    { label: t('userForm.role'), value: data?.role }
  ]

  return <ContentDetails data={info} title={data?.full_name}></ContentDetails>
}

export default UserDetails
