import { Modal } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function BaseModal (props: any) {
  const { t } = useTranslation()

  const {
    visible,
    handleOk,
    handleCancel,
    content,
    title,
    cancelText,
    okText,
    isCreate,
    isSubmit,
    ...rest
  } = props
  return (
    <Modal
      title={<div className="font-bold">{title}</div>}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText={cancelText || t('cancel')}
      okText={okText || isCreate ? t('create') : t('update')}
      okButtonProps={{ disabled: !!isSubmit }}
      {...rest}
    >
      {content}
    </Modal>
  )
}
