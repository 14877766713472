import { Select } from 'antd'
import { useAppSelector } from '../../../../hooks'
import { storage } from '../../../../constants/storage'
import { utils } from '../../../../utils/utils'

import './index.scss'

export const NavTenantSelect = () => {
  const auth = useAppSelector((state) => state.auth)

  const tenants = auth.userInfo?.tenant_infos || []
  if (utils.getStorage(storage.CURRENT_TENANT_ID) == null && tenants[0]) {
    utils.setStorage(storage.CURRENT_TENANT_ID, tenants[0].value)
  }
  const currentTenantId = parseInt(utils.getStorage(storage.CURRENT_TENANT_ID)) || ''

  const handleChangeTenant = async (value: any) => {
    utils.setStorage(storage.CURRENT_TENANT_ID, value)
    window.location.reload()
  }

  return (
    <Select
      value={currentTenantId}
      style={{ width: 300 }}
      className="select-tenant"
      onSelect={(value) => {
        handleChangeTenant(value)
      }}
      options={tenants}
    />
  )
}

export default NavTenantSelect
