import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'

const DetailItem = (props: any) => {
  const { tenant } = props
  const { t } = useTranslation()
  return (
    <>
      <Row gutter={20}>
        <Col span={16} className="p-2">
          <span className="font-bold mr-1">{t('message.detailsTenants.name')}</span>{' '}
          {tenant.name}
        </Col>
        <Col span={24} className="p-2">
          <div className="font-bold mb-4">
            <span>{t('message.detailsTenants.description')}</span>
          </div>
          {tenant.description && (
            <div className="border-solid border border-slate-300">
              <div
                className="p-4"
                dangerouslySetInnerHTML={{ __html: tenant.description }}
              ></div>
            </div>
          )}
        </Col>
      </Row>
    </>
  )
}

export default DetailItem
