import {
  EditOutlined,
  EyeOutlined
} from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { Card, Spin } from 'antd'
import BaseTable from '../../../../components/BaseTable'
import { HistoryDeviceServices } from '../../../../services/HistoryDeviceServices'
import BaseModal from '../../../../components/BaseModal'
import FormItem from './components/Form'
import { useAppDispatch } from '../../../../hooks'
import { useHistory, useParams } from 'react-router-dom'
import { configuration } from '../../../../configuration'
import { utils } from '../../../../utils/utils'
import { selectDeviceHistory } from '../../../../redux/slices/deviceHistorySlice'
import { modalType } from '../../../../constants/deviceHistory'
import { DeviceHistoryType } from '../../../../types/deviceHistoryType'
import { filterScreen } from '../../../../constants/filterScreen'
import { useTranslation } from 'react-i18next'

function HistoryDevices () {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [dataSource, setDataSource] = useState<any>([])
  const queryParams = new URLSearchParams(window.location.search)
  const pageCurrent = Number(queryParams.get('page') || 1)
  const textSearch = queryParams.get('keywords') || ''
  const [currentPage, setCurrentPage] = useState(pageCurrent)
  const params = useParams<any>()
  const deviceId = params.id

  const [totalItems, setTotalItems] = useState(0)
  const [dataRecord, setDataRecord] = useState({})

  const [isEditFormModalVisible, setIsEditFormModalVisible] = useState<boolean>()
  const [loading, setLoading] = useState(true)

  const getData = async (page: number, value: any) => {
    try {
      const res = (await HistoryDeviceServices.getListHistoryDevices(deviceId, page, value)).data || []
      setDataSource(res.history_devices)
      setTotalItems(res.meta.total_result)
    } catch (error) {
      utils.handleError(error)
    } finally {
      setLoading(false)
    }
  }

  const handleActions = (record: DeviceHistoryType, type: string) => {
    dispatch(selectDeviceHistory(record))

    switch (type) {
      case modalType.View:
        history.push(`/device-histories/${record.id}`, { from: `/devices/${deviceId}` })
        break
      default:
        break
    }
  }

  useEffect(() => {
    document.title = `Devices management - ${t('pageTitle.historyDeviceDetails')}`
    getData(pageCurrent, textSearch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns: any = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 50,
      sorter: {
        compare: (a: any, b: any) => utils.sortAction(a, b, 'id')
      }
    },
    {
      title: t('pageTitle.management.deviceHistory.table.Updater'),
      dataIndex: 'user_name',
      width: 100,
      sorter: {
        compare: (a: any, b: any) => utils.sortAction(a, b, 'user_name')
      }
    },
    {
      title: t('pageTitle.management.deviceHistory.table.updated'),
      dataIndex: 'updated_at',
      width: 100,
      sorter: {
        compare: (a: any, b: any) => utils.sortAction(a, b, 'updated_at')
      },
      render: (record) => utils.withTimeZone(record, utils.dateTimeFormat())
    },
    {
      title: t('pageTitle.management.deviceHistory.table.status'),
      dataIndex: 'device_status',
      width: 100,
      sorter: {
        compare: (a: any, b: any) => utils.sortAction(a, b, 'device_status')
      },
      render: (record) => <div className='capitalize'>{record}</div>
    },
    {
      title: t('pageTitle.management.deviceHistory.table.assignee'),
      dataIndex: 'assignee',
      width: 100,
      sorter: {
        compare: (a: any, b: any) => utils.sortAction(a, b, 'assignee')
      }
    },
    {
      title: t('pageTitle.management.deviceHistory.table.note'),
      dataIndex: 'note',
      width: 250,
      sorter: {
        compare: (a: any, b: any) => utils.sortAction(a, b, 'note')
      },
      render: (record) => <div className='whitespace-pre-wrap'>{record}</div>
    },
    {
      title: t('pageTitle.management.deviceHistory.table.actions'),
      width: 100,
      fixed: 'right',
      align: 'center',
      render: (record) => {
        return (
          <>
            <EyeOutlined
              className={'text-xl px-2 hover:text-blue-400'}
              onClick={() => handleActions(record, modalType.View)}
            />
            <EditOutlined
              className={'text-xl px-2 hover:text-blue-400'}
              onClick={() => {
                setDataRecord(record)
                setIsEditFormModalVisible(true)
              }}
            />
          </>
        )
      }
    }
  ]

  if (loading) {
    return (
      <div className="flex justify-center mt-4">
        <Spin></Spin>
      </div>
    )
  }

  return (
    <Card className="mt-6">
      <h1 className="text-2xl">{t('pageTitle.management.deviceHistory.title')}</h1>
      <BaseTable
        callbackHandleAutoSearch={async (keywords: string) => await getData(1, keywords)}
        columns={columns}
        dataSource={dataSource}
        callbackAfterChangePage={async (page: number) => await getData(page, textSearch)}
        total={totalItems}
        pageSize={configuration.pageSize}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        filterScreen={filterScreen.historyDevices}
      />
      {isEditFormModalVisible && (
        <BaseModal
          visible={isEditFormModalVisible}
          handleCancel={() => setIsEditFormModalVisible(false)}
          content={
            <FormItem
              data={dataRecord}
              isCreateNew
              forceRefeshTable={() => {
                getData(pageCurrent, textSearch)
                setIsEditFormModalVisible(false)
              }}
            />
          }
          title="History note"
          width={1000}
          footer={null}
        />
      )}
    </Card>
  )
}

export default HistoryDevices
