/* eslint-disable react-hooks/exhaustive-deps */
import { Route, useHistory } from 'react-router-dom'

import { storage } from '../../constants/storage'
import { routesAuthObj } from '../../routes/routes'
import { utils } from '../../utils/utils'

function AppRoute (props: any) {
  const {
    component: Component,
    layout: Layout,
    role,
    ignoredAuthentication,
    ...rest
  } = props
  const history = useHistory()

  const accessToken = utils.getStorage(storage.ACCESS_TOKEN)

  const getLayout = (props: any) => {
    if (Layout) {
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    } else {
      return <Component {...props} />
    }
  }

  if (!accessToken && !ignoredAuthentication) {
    history.push(`${routesAuthObj.login.path}`)
    return
  }

  return <Route {...rest} render={(props: any) => getLayout(props)} />
}

export default AppRoute
