import { apiConstants } from './ApiConstants'
import { ApiServices } from './ApiServices'

export const DeviceServices = {
  getListDevices: async (
    params: {
      page: number
      limit?: number
      keywords?: string
      status?: string
    }, sortParams, searchParams: any = {
      page: 1
    }
  ) => {
    const res = await ApiServices().get(apiConstants.DEVICE, {
      params: {
        ...params,
        sort_order: sortParams,
        category: searchParams.category,
        status: searchParams.status,
        min_remaining_price: searchParams.min_remaining_price,
        max_remaining_price: searchParams.max_remaining_price,
        start_purchase_date: searchParams.start_purchase_date,
        end_purchase_date: searchParams.end_purchase_date,
        start_borrow_date: searchParams.start_borrow_date,
        end_borrow_date: searchParams.end_borrow_date,
        start_return_date: searchParams.start_return_date,
        end_return_date: searchParams.end_return_date
      }
    })
    return res.data
  },
  getListDeviceByGroups: async (
    groupId: string,
    params: {
      page: number
      limit?: number
      keywords?: string
      status?: string
    }, sortParams, searchParams: any = {
      page: 1
    }) => {
    const res = await ApiServices().get(`${apiConstants.GROUPS}/${groupId}/devices`, {
      params: {
        ...params,
        sort_order: sortParams,
        category: searchParams.category,
        status: searchParams.status,
        min_remaining_price: searchParams.min_remaining_price,
        max_remaining_price: searchParams.max_remaining_price,
        start_purchase_date: searchParams.start_purchase_date,
        end_purchase_date: searchParams.end_purchase_date,
        start_borrow_date: searchParams.start_borrow_date,
        end_borrow_date: searchParams.end_borrow_date,
        start_return_date: searchParams.start_return_date,
        end_return_date: searchParams.end_return_date
      }
    })
    return res.data
  },
  exportCSVListDevices: async (keywords?: string, sortParams?: string) => {
    const res = await ApiServices()
      .get(apiConstants.EXPORT_DEVICES, { params: { keywords, sort_order: sortParams } })
      .then((response) => {
        const file = new Blob([response.data], { type: 'text/csv' })
        const url = window.URL.createObjectURL(file)
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = 'devices'
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      })

    return res
  },
  getListMyDevices: async (
    params: {
      page: number
      limit?: number
      keywords?: string
    }, sortParams, searchParams: any = { page: 1 }
  ) => {
    const res = await ApiServices().get(apiConstants.MY_DEVICE, {
      params: {
        ...params,
        sort_order: sortParams,
        category: searchParams.category,
        status: searchParams.status,
        min_remaining_price: searchParams.min_remaining_price,
        max_remaining_price: searchParams.max_remaining_price,
        start_purchase_date: searchParams.start_purchase_date,
        end_purchase_date: searchParams.end_purchase_date,
        start_borrow_date: searchParams.start_borrow_date,
        end_borrow_date: searchParams.end_borrow_date,
        start_return_date: searchParams.start_return_date,
        end_return_date: searchParams.end_return_date
      }
    })
    return res.data
  },
  getListDevicesName: async (
    params: {
      name?: string
    }
  ) => {
    const res = await ApiServices().get(apiConstants.DEVICE_NAME, {
      params
    })
    return res.data
  },
  getDetail: async (id: string) => {
    const res = await ApiServices().get(`${apiConstants.DEVICE}/${id}`)
    return res.data
  },
  deleteDevice: async (id: string) => {
    const res = await ApiServices().delete(`${apiConstants.DEVICE}/${id}`)
    return res.data.meta
  },
  updateDevice: async (id: string, device: any) => {
    const res = await ApiServices().patch(
      `${apiConstants.DEVICE}/${id}`,
      device
    )
    return res.data.meta
  },
  createDevice: async (device: any) => {
    const res = await ApiServices().post(apiConstants.DEVICE, device)
    return res.data.meta
  },
  getListStatus: async () => {
    const res = await ApiServices().get(`${apiConstants.DEVICE}/statuses`)
    return res.data
  },
  getListUsersForSelect: async (
    params: {
      page: number
      limit?: number
      keywords?: string
    } = {
      page: 1
    }
  ) => {
    const res = await ApiServices().get(`${apiConstants.USERS_SEARCH}`, {
      params
    })
    return res.data
  }
}
