import { Form } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import BaseModal from '../../../components/BaseModal'
import { modalType, currency as currencyType } from '../../../constants/device'
import { DeviceServices } from '../../../services/DeviceServices'
import { DeviceType } from '../../../types/deviceType'
import { utils } from '../../../utils/utils'
import DeviceForm from './DeviceForm'
const dateFormat = 'YYYY/MM/DD'
interface ModalProps {
  isModalVisible: boolean
  type: string
  handleCancel: (e?: React.MouseEvent<HTMLElement>) => void
  getData: () => Promise<void>
  dataDetail?: any
}

const EditAddModal = ({
  isModalVisible,
  type,
  handleCancel,
  getData,
  dataDetail = {}
}: ModalProps) => {
  const [form] = Form.useForm()
  const [photoIds, setPhotoIds] = useState([])
  const [isSubmit, setIsSubmit] = useState<boolean>(false)

  useEffect(() => {
    if (isModalVisible) {
      if (Object.keys(dataDetail).length) {
        form.setFieldsValue({
          ...dataDetail,
          activation_date: moment(dataDetail?.activation_date).isValid() ? moment(dataDetail?.activation_date) : undefined,
          purchase_date: moment(dataDetail?.purchase_date).isValid() ? moment(dataDetail?.purchase_date) : undefined,
          borrow_date: moment(dataDetail?.borrow_date).isValid() ? moment(dataDetail?.borrow_date) : undefined,
          return_date: moment(dataDetail?.return_date).isValid() ? moment(dataDetail?.return_date) : undefined,
          assignee_id: dataDetail?.assignee_id.toString()
        })
      } else {
        form.resetFields()
        form.setFieldsValue({
          original_price_unit: currencyType.VND.value
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dataDetail), isModalVisible])

  const formatValue = (value: DeviceType) => {
    const bodyFormData = new FormData()

    value.photos?.forEach((photo: any) => {
      if (photo.originFileObj) { bodyFormData.append('device[photos][]', photo.originFileObj) }
    })

    photoIds?.forEach((id: any) => bodyFormData.append('device[photo_ids][]', id))
    bodyFormData.append('device[device_type]', value.device_type || '')
    bodyFormData.append('device[activation_date]', (value.activation_date ? moment(value.activation_date).format(dateFormat) : ''))
    bodyFormData.append('device[warranty_period]', value.warranty_period || '')
    bodyFormData.append('device[supplier]', value.supplier || '')
    bodyFormData.append('device[invoice_number]', value.invoice_number || '')
    bodyFormData.append('device[original_price_unit]', value.original_price_unit || 'vnd')
    bodyFormData.append('device[manager_notes]', value.manager_notes || '')
    bodyFormData.append('device[category]', value.category || '')
    bodyFormData.append('device[serial_number]', value.serial_number || '')
    bodyFormData.append('device[name]', value.name || '')
    bodyFormData.append('device[purchase_date]', value.purchase_date ? moment(value.purchase_date).format(dateFormat) : '')
    bodyFormData.append(
      'device[original_price]',
      `${value.original_price}` || ''
    )
    bodyFormData.append(
      'device[allotment_time]',
      `${value.allotment_time}` || ''
    )
    bodyFormData.append('device[assignee_id]', value.assignee_id || '')
    bodyFormData.append('device[status]', value.status || '')
    bodyFormData.append('device[description]', value.description || '')
    bodyFormData.append('device[borrow_date]', (value.borrow_date ? moment(value.borrow_date).format(dateFormat) : ''))
    bodyFormData.append('device[return_date]', (value.return_date ? moment(value.return_date).format(dateFormat) : ''))
    return bodyFormData
  }

  const handleFinish = async (values: DeviceType) => {
    const bodyFormData = formatValue(values)
    setIsSubmit(true)
    try {
      const res =
        type === modalType.Add
          ? await DeviceServices.createDevice(bodyFormData)
          : await DeviceServices.updateDevice(dataDetail.id, bodyFormData)
      getData()
      handleCancel()
      form.resetFields()
      utils.handleSuccessNotify(res.message)
    } catch (error) {
      utils.handleError(error)
    }
    setIsSubmit(false)
  }

  return (
    <>
      {isModalVisible && (
        <BaseModal
          title={`${type}`}
          visible={isModalVisible}
          onOk={() => form.submit()}
          onCancel={handleCancel}
          closable={false}
          width={1300}
          isCreate={type === modalType.Add}
          forceRender
          isSubmit={isSubmit}
          content={
            <DeviceForm
              form={form}
              dataDetail={dataDetail}
              handleFinish={handleFinish}
              type={type}
              isCreate={type === modalType.Add}
              photoIds={photoIds}
              setPhotoIds={setPhotoIds}
            />
          }
        />
      )}
    </>
  )
}

export default EditAddModal
