import { DatePicker, Select } from 'antd'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { COMMON_PRIORITIES, COMMON_STATUSES, COMMON_TRACKERS } from '../../constants/request'
import { handleSelect } from '../../redux/slices/requestFilterSlice'
import moment from 'moment'
import { dateFormat } from '../../constants/common'
import { useTranslation } from 'react-i18next'

const RequestFilter = (props: {callbackFilter?: any }) => {
  const { t } = useTranslation()
  const { callbackFilter } = props
  const { RangePicker } = DatePicker
  const dispatch = useAppDispatch()
  const optionsTracker = [...COMMON_TRACKERS, { value: 'all', label: t('common.all') }]
  const optionsPriority = [...COMMON_PRIORITIES, { value: 'all', label: t('common.all') }]
  const optionsStatus = [...COMMON_STATUSES, { value: 'all', label: t('common.all') }]
  const requestFilter = useAppSelector((state) => state.requestFilter)

  const handleFilterRequest = (value: any, type: string) => {
    dispatch(handleSelect({ value, type }))
    callbackFilter && callbackFilter({ value, type })
  }

  return (
    <div>
      <div className='flex space-x-8'>
        <Select
          value={requestFilter.tracker}
          style={{ width: 200 }}
          placeholder={t('pageTitle.search.byTracker')}
          options={optionsTracker}
          onChange={value => handleFilterRequest(value, 'tracker')}
        />
        <Select
          value={requestFilter.priority}
          style={{ width: 200 }}
          placeholder={t('pageTitle.search.byPriority')}
          options={optionsPriority}
          onChange={value => handleFilterRequest(value, 'priority')}
        />
        <Select
          value={requestFilter.status}
          style={{ width: 200 }}
          placeholder={t('pageTitle.search.byStatus')}
          options={optionsStatus}
          onChange={value => handleFilterRequest(value, 'status')}
        />
      </div>
      <div className='flex space-x-8 mt-4 w-7/12'>
        <RangePicker
          placeholder={[t('pageTitle.search.byStartDateForm'), t('pageTitle.search.byStartDateTo')]}
          className='w-full'
          value={[
            requestFilter.start_date.length ? moment(`${requestFilter.start_date[0]}`, dateFormat) : null,
            requestFilter.start_date.length ? moment(`${requestFilter.start_date[1]}`, dateFormat) : null
          ]}
          onChange={value => {
            const startDateRange = value
              ? value.map((v: any) => {
                return v.format('YYYY/MM/DD')
              })
              : []
            handleFilterRequest(startDateRange, 'start_date')
          }}
        />
        <RangePicker
          placeholder={[t('pageTitle.search.byDueDateForm'), t('pageTitle.search.byDueDateTo')]}
          className='w-full'
          value={[
            requestFilter.due_date.length ? moment(`${requestFilter.due_date[0]}`, dateFormat) : null,
            requestFilter.due_date.length ? moment(`${requestFilter.due_date[1]}`, dateFormat) : null
          ]}
          onChange={value => {
            const dueDateRange = value
              ? value.map((v: any) => {
                return v.format('YYYY/MM/DD')
              })
              : []
            handleFilterRequest(dueDateRange, 'due_date')
          }}
        />
      </div>
    </div>
  )
}

export default RequestFilter
