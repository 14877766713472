import { Card, Col, Divider, Image, Row } from 'antd'
import avatar from '../../assets/images/avatar.jpg'
import { gapi } from 'gapi-script'
import { useEffect, useState } from 'react'
import { GoogleLogin } from 'react-google-login'
import logo from '../../assets/images/loginLogo.jpg'
import { useTranslation } from 'react-i18next'
import backGround from '../../assets/images/bg.jpeg'
import './index.scss'
import { apiConstants } from '../../services/ApiConstants'
import { languageObj } from '../../constants/locales/languages'

const backgroundStyle = {
  backgroundImage: `url(${backGround})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  height: '100vh'
}

const Login = () => {
  const { t } = useTranslation()
  const [showComponent, setShowComponent] = useState(false)
  const handleLogin = async (googleData: any) => {
    const email = googleData.profileObj.email
    const baseURl = process.env.REACT_APP_BASE_URL_REQUEST_VID
    const endPoint = apiConstants.loginOauth2
    const serviceId = process.env.REACT_APP_SERVICE_ID
    const clientId = process.env.REACT_APP_CLIENT_ID
    window.location.href = (`${baseURl}/${endPoint}?service_id=${serviceId}&client_id=${clientId}&response_type=code&email=${email}`)
  }
  const responseGoogle = (response: any) => {
    console.log(response)
  }

  const handleOauth2 = () => {
    const baseURl = process.env.REACT_APP_BASE_URL_REQUEST_VID
    const endPoint = apiConstants.loginOauth2
    const serviceId = process.env.REACT_APP_SERVICE_ID
    const clientId = process.env.REACT_APP_CLIENT_ID
    window.location.href = (`${baseURl}/${endPoint}?service_id=${serviceId}&client_id=${clientId}&response_type=code`)
  }

  const handleLanguageChange = (language) => {
    localStorage.setItem('language', language)
    window.location.reload()
  }

  const handleOauth2AutoLogin = () => {
    const baseURl = process.env.REACT_APP_BASE_URL_REQUEST_VID
    const endPoint = apiConstants.loginOauth2
    const serviceId = process.env.REACT_APP_SERVICE_ID
    const clientId = process.env.REACT_APP_CLIENT_ID
    window.location.href = (`${baseURl}/${endPoint}?service_id=${serviceId}&client_id=${clientId}&response_type=code&autoLogin=true`)
  }

  const renderLoginGoogle = (
    <div>
      <Divider className="d-flex justify-content-center login-title">
      <div className="flex justify-center items-center">
        <Image src={logo} alt="logo" className="mr-3 logo-login" preview={false}/>
          <div className="text-2xl font-medium text-white">VAREAL</div>
      </div>
      <div className="text-xl font-medium text-white mt-4 pb-1">{t('login.welcome')}</div>
      <div className=" text-base text-white mb-2">{t('login.titleSignIn')}</div>
      </Divider>
      <div className="d-flex justify-content-center flex-col items-center ">
        {process.env.REACT_APP_GG_CLIENT_ID && (
          <GoogleLogin
            clientId={process.env.REACT_APP_GG_CLIENT_ID || ''}
            buttonText="Login with Google"
            onSuccess={handleLogin}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
            className="googleBtn"
          />
        )}
        <div onClick={handleOauth2} className='section-login__Vid bg-[#fff] w-[180px] mt-[5px] cursor-pointer'>
          <img
            src={avatar}
            alt="logo-login"
            width={50}
          />
          Login with Vareal ID
        </div>
      </div>
      <div className="flex justify-center text-[16px] pt-5">
        {Object.values(languageObj).map((e) => {
          return (
            <span
              key={e.key}
              className="px-3 cursor-pointer hover:underline text-white"
              onClick={() => handleLanguageChange(e.key)}
            >
              {e.text}
            </span>
          )
        })}
      </div>
    </div>
  )

  useEffect(() => {
    gapi.load('client:auth2', () => {
      gapi.auth2.init({ clientId: process.env.REACT_APP_GG_CLIENT_ID })
    })
  }, [])

  useEffect(() => {
    handleOauth2AutoLogin()
    const timer = setTimeout(() => {
      setShowComponent(true)
    }, 2000)

    return () => clearTimeout(timer)
  }, [])

  return (
    showComponent &&
    (<div style={backgroundStyle}>
      <div className="container d-flex flex-column justify-content-center h-100">
        <Row justify="center">
          <Col xs={20} sm={20} md={20} lg={7}>
            <Card className="border-custom" style={{ background: 'rgba(0,0,0,0.3)', width: '410px', height: '350px' }}>
                <Row justify="center">
                  <Col xs={24} sm={24} md={20} lg={20}>
                    {renderLoginGoogle}
                  </Col>
                </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </div>)
  )
}

export default Login
