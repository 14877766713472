import { createSlice } from '@reduxjs/toolkit'
interface authState {
  userInfo: any
}

const initialState: authState = {
  userInfo: {}
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    updateUserInfo: (state, action) => {
      state.userInfo = action.payload
    }
  }
})

export const { updateUserInfo } = authSlice.actions

export default authSlice.reducer
