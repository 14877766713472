import { Breadcrumb, Layout, Menu } from 'antd'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import NavProfile from '../../../pages/users/Component/NavProfile'
import NavTenantSelect from '../../../pages/users/Component/NavTenantSelect'
import './index.scss'
import { SidebarMenu } from './SidebarMenu'
import { titleDevices } from '../../../constants/device'
import { deviceHistoryTitle } from '../../../constants/deviceHistory'
import { routesObj } from '../../../routes/routes'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { role } from '../../../constants/role'
import { resetFilterDevice } from '../../../redux/slices/deviceFilterSlice'
import { resetFilterRequest } from '../../../redux/slices/requestFilterSlice'
import { resetFilterUser } from '../../../redux/slices/userFilterSlice'
import { resetFilterTenant } from '../../../redux/slices/tenantFilterSlice'
import { handleKeysMenu } from '../../../redux/slices/keysMenuSlice'
import { utils } from '../../../utils/utils'
import ListService from '../../ListService'
import SelectLanguage from '../SelectLanguage'
import { useTranslation } from 'react-i18next'

const { Header, Content, Footer, Sider } = Layout

// interface MenuOnClickType {
//   key: React.Key
// }

const App: React.FC = (props: any) => {
  const history = useHistory()
  const [collapsed, setCollapsed] = useState(false)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [selectedKey, setSelectedKey] = useState<string>(
    history.location.pathname
  )
  const auth = useAppSelector((state) => state.auth)
  const keysMenu = useAppSelector((state) => state.keysMenu)
  const keyStorage = utils.getStorage('keys_submenu')?.split(',') || []

  const initKeys = () => {
    if (window.location.pathname !== `${routesObj?.dashboard.path}`) {
      return keysMenu.keys
    } else {
      return [`${routesObj?.dashboard.path}`]
    }
  }

  const [keysDefault, setKeysDefault] = useState(initKeys())

  const handleClickMenuItem = (info: any) => {
    history.push(`${info.key}`)
    utils.setStorage('keys_submenu', info.keyPath[1])
    dispatch(resetFilterDevice())
    dispatch(resetFilterRequest())
    dispatch(resetFilterUser())
    dispatch(resetFilterTenant())
  }
  const handleOpenParentMenu = (info: any) => {
    dispatch(handleKeysMenu(info))
  }

  useEffect(() => {
    let pathname = history.location.pathname?.replace(/^\/([^/]*).*$/, '/$1')
    if (pathname === '/device-histories') {
      pathname = '/devices'
    }

    setSelectedKey(pathname)
  }, [history.location.pathname])

  // useEffect(() => {
  //   if (window.location.pathname === '/') {
  //     utils.setStorage('keys_submenu', null)
  //   }
  // }, [])

  useEffect(() => {
    if (window.performance && keysMenu.keys.length === 0) {
      setKeysDefault(keyStorage)
    }
  }, [])

  const breadcrumbShow = (key: string) => {
    if (history.location.pathname?.includes(deviceHistoryTitle)) {
      return routesObj.deviceHistoryDetails.title
    } else if (key.includes(titleDevices)) {
      return routesObj.devices.title
    } else if (key.includes(`${routesObj.myDevices.path}`)) {
      return routesObj.myDevices.title
    } else if (key.includes(`${routesObj.users.path}`)) {
      return routesObj.users.title
    } else if (key.includes(`${routesObj.groups.path}`)) {
      return routesObj.groups.title
    } else if (key.includes(`${routesObj.myGroup.path}`)) {
      return routesObj.myGroup.title
    } else if (key.includes(`${routesObj.requests.path}`)) {
      if (auth.userInfo.role === role.user.value) {
        return t('requests.myRequest')
      } else {
        return routesObj.requests.title
      }
    } else if (key.includes(`${routesObj.userProfile.path}`)) {
      return routesObj.userProfile.title
    }
  }

  return (
    <>
      <Header className="site-layout-background header" style={{ padding: 0, zIndex: 1 }}>
        <div className="brand-name">Devices management</div>
        <div className="nav-right">
          <NavTenantSelect />
          <SelectLanguage />
          <ListService />
          <NavProfile />
        </div>
      </Header>
      <Layout style={{ minHeight: '100vh', zIndex: 1 }} hasSider >
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <Menu
            theme="dark"
            selectedKeys={[selectedKey]}
            defaultOpenKeys={keysDefault.length ? keysDefault : keyStorage}
            mode="inline"
            items={SidebarMenu()}
            onClick={handleClickMenuItem}
            onOpenChange={(info: any) => handleOpenParentMenu(info)}
            getPopupContainer={trigger => trigger.parentElement}
          />
        </Sider>
        <Layout>
          <Layout className="site-layout" style={{ zIndex: 1 }}>
            <Content className="mx-8">
              <Breadcrumb style={{ margin: '16px 0' }} data-testid='breadcrumb'>
                <Breadcrumb.Item className="text-md font-bold">
                  {breadcrumbShow(`${selectedKey}`)}
                </Breadcrumb.Item>
              </Breadcrumb>
              <div className="p-4 bg-white">{props.children}</div>
            </Content>
            <Footer style={{ textAlign: 'center', fontSize: '16px', color: '#212529' }}>
              © 2023 <b>Vareal K.K.</b>
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  )
}

export default App
