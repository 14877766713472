import axios, { AxiosRequestConfig } from 'axios'
import { storage } from '../constants/storage'
import { utils } from '../utils/utils'
import { apiConstants } from './ApiConstants'
import i18n from '../lang/i18n'
import { AuthOtherService } from '../services/AuthOtherService'

const userId: any = utils.getStorage(storage.ID_USER) || ''
const checkIsLogin = async () => {
  const res = await AuthOtherService.checkIsLogin(userId)
  const isLogin = res?.data?.login?.online
  if (isLogin !== undefined && !isLogin) {
    utils.handleError(i18n.t('message.apiService'))
    setTimeout(() => {
      utils.logout()
    }, 1000)
  }
}

export const ApiServices = (
  config: AxiosRequestConfig = {},
  passToken = true
) => {
  checkIsLogin()
  function apiRequest () {
    if (process.env.NODE_ENV === 'development') {
      return window.location.origin.replace(window.location.port, process.env.REACT_APP_DEV_BASE_URL_PORT)
    } else {
      return `${window.location.origin}/api/v1`
    }
  }
  const instanceAxios = axios.create({
    baseURL: apiRequest(),
    headers: { 'Content-Type': 'application/json', tenantId: utils.getStorage(storage.CURRENT_TENANT_ID) },
    ...config
  })
  // Add a request interceptor
  instanceAxios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      const token = utils.getStorage(storage.ACCESS_TOKEN)
      if (config.headers && token && passToken) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    },
    async function (error) {
      // Do something with request error
      return await Promise.reject(error)
    }
  )

  // Add a response interceptor
  instanceAxios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    async function (error) {
      const originalConfig = error.config
      if (
        originalConfig.url !== `${apiConstants.LOGIN_VIA_GG}` &&
        error.response
      ) {
        // Access Token was expired
        if (
          error.response.status === 401 &&
          !originalConfig._retry &&
          error.response.data.meta.require_refresh
        ) {
          originalConfig._retry = true
          const configReToken = {
            baseURL: apiRequest(),
            headers: {
              'Content-Type': 'application/json',
              tenantId: utils.getStorage(storage.CURRENT_TENANT_ID)
            }
          }
          try {
            const { data } = await axios.post(
              `${apiConstants.RE_GET_ACCESS_TOKEN}`,
              {
                refresh_token: utils.getStorage(storage.REFRESH_TOKEN)
              },
              configReToken
            )
            const { access_token: accessToken, refresh_token: refreshToken } =
              data.data

            utils.setStorage(storage.ACCESS_TOKEN, accessToken)
            utils.setStorage(storage.REFRESH_TOKEN, refreshToken)

            return await instanceAxios(originalConfig)
          } catch (_error) {
            if (_error.response.status === 401) {
              utils.handleError(i18n.t('message.apiService'))
              setTimeout(() => {
                utils.logout()
              }, 1000)
            }
          }
        } else if (
          error.response.status === 401) {
          utils.handleError(i18n.t('message.apiService'))
          setTimeout(() => {
            utils.logout()
          }, 2000)
        }
      }
      return await Promise.reject(error)
    }
  )
  return instanceAxios
}
