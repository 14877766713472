import { Button, Col, Form, Input, Row } from 'antd'
import { useEffect, useState } from 'react'
import { role } from '../../../../../../constants/role'
import { useAppSelector } from '../../../../../../hooks'
import { HistoryDeviceServices } from '../../../../../../services/HistoryDeviceServices'
import { HistoryDeviceType } from '../../../../../../types/historyDeviceType'
import { utils } from '../../../../../../utils/utils'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const FormItem = (props: any) => {
  const { t } = useTranslation()
  const { forceRefeshTable, data = {} } = props
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const auth = useAppSelector((state) => state.auth)
  const params = useParams<any>()
  const deviceId = params.id

  const isCreateNew = Boolean(!Object.keys(data).length)

  const formatValue = (value: HistoryDeviceType) => {
    return {
      ...value,
      user_id: Number(value.user_id),
      device_id: Number(value.device_id)
    }
  }

  const handleFinish = async (value: any) => {
    try {
      setIsSubmitting(true)
      const formatterValue = formatValue(value)
      await HistoryDeviceServices.updateHistoryDevice(deviceId, data.id, formatterValue)
      utils.handleSuccessNotify(t('message.history.updateSuccessfully'))
      forceRefeshTable && forceRefeshTable()
    } catch (e) {
      utils.handleError(e)
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    if (Object.keys(data).length) {
      form.setFieldsValue({
        note: data.note
      })
    }
  }, [JSON.stringify(data)])

  return (
    <Form
      scrollToFirstError
      form={form}
      layout="vertical"
      onFinish={handleFinish}
    >
      <Row gutter={20}>
        <Col span={24}>
          {auth.userInfo.role !== role.user.value && (
            <Form.Item label={t('message.history.note')} name="note">
              <Input.TextArea autoSize={{ minRows: 5, maxRows: 5 }} />
            </Form.Item>
          )}
        </Col>
      </Row>
      <Button
        type="primary"
        htmlType="submit"
        loading={isSubmitting}
        className="block ml-auto"
      >
        {isCreateNew ? t('message.history.create') : t('message.history.update')}
      </Button>
    </Form>
  )
}

export default FormItem
