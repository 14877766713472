import { GroupsPostType, GroupsPatchType } from '../types/groupsType'
import { apiConstants } from './ApiConstants'
import { ApiServices } from './ApiServices'

export const GroupsServices = {
  async getListGroup (page: number, keywords: string, sortParams?: any) {
    return await ApiServices().get(apiConstants.GROUPS, {
      params: {
        page,
        keywords,
        sort_order: sortParams
      }
    })
  },
  async getListMyGroup (page: number, keywords: string, sortParams?: any) {
    return await ApiServices().get(`${apiConstants.GROUPS}/my_groups`, {
      params: {
        page,
        keywords,
        sort_order: sortParams
      }
    })
  },
  async createNewGroup (data: GroupsPostType) {
    return await ApiServices().post(apiConstants.GROUPS, data)
  },
  async updateGroup (id: number | string, data: GroupsPatchType) {
    return await ApiServices().patch(`${apiConstants.GROUPS}/${id}`, data)
  },
  async getDetail (id: number | string) {
    return await ApiServices().get(`${apiConstants.GROUPS}/${id}`)
  },
  async deleteGroup (id: number | string) {
    return await ApiServices().delete(`${apiConstants.GROUPS}/${id}`)
  },
  async getListUsersTenant () {
    return await ApiServices().get(`${apiConstants.GROUPS}/list_user_tenant`)
  }
}
