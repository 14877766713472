import axios from 'axios'
import { apiConstants } from './ApiConstants'

export const ApiListService = {
  async listService (env: string, idToken: any, serviceId: any, userId: any) {
    const config: any = {
      headers: { 'Content-Type': 'application/json', 'X-User-Id': userId }
    }
    return await axios.get(`${env}/${apiConstants.SERVICE}?id_token=${idToken}&service_id=${serviceId}`, config)
  }
}
