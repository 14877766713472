import { Spin } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { deviceTypeI18n, statusDeviceI18n } from '../../../../../constants/device'
import { role } from '../../../../../constants/role'
import { useAppSelector } from '../../../../../hooks'
import { useRouter } from '../../../../../hooks/useRouter'
import { HistoryDeviceServices } from '../../../../../services/HistoryDeviceServices'
import { DeviceHistoryType } from '../../../../../types/deviceHistoryType'
import { utils } from '../../../../../utils/utils'
import ContentDetails from '../../../../request/components/ContentDetails'

interface IHistoryDeviceParams {
  id: string
}

const HistoryDeviceDetails = () => {
  const [deviceHistory, setDeviceHistory] = useState<DeviceHistoryType>()
  const params = useParams<IHistoryDeviceParams>()
  const auth = useAppSelector((state) => state.auth)
  const deviceHistoryFilter = useAppSelector((state) => state.deviceHistory)
  const router = useRouter()
  const prevLocation = useRef(router.history.location.state)
  const [loading, setLoading] = useState(true)

  const getDeviceHistory = async () => {
    try {
      const res = await HistoryDeviceServices.getDetail(params.id)
      setDeviceHistory(res.history_device)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  const goBack = () => {
    if (prevLocation.current !== undefined) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      router.history.push(prevLocation.current['from'])
      router.history.push({
        pathname: router.history.location.pathname,
        search: deviceHistoryFilter?.url
      })
    } else {
      router.history.push(
        router.history.location.pathname?.replace(params.id, '')
      )
    }
  }

  useEffect(() => {
    getDeviceHistory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const info = [
    { label: 'Updater', value: deviceHistory?.user_name },
    { label: 'Updated', value: utils.withTimeZone(deviceHistory?.updated_at, utils.dateTimeFormat()) },
    { label: 'Type', value: deviceHistory?.device?.device_type },
    { label: 'Category', value: deviceTypeI18n(deviceHistory?.device?.category) },
    { label: 'Code', value: deviceHistory?.device?.code },
    { label: 'Serial Number', value: deviceHistory?.device?.serial_number },
    { label: 'Purchase Date', value: utils.withTimeZone(deviceHistory?.device?.purchase_date, utils.dateFormat()) },
    {
      label: 'Activation Date',
      value: deviceHistory?.device?.activation_date ? utils.withTimeZone(deviceHistory?.device?.activation_date, utils.dateFormat()) : ''
    },
    { label: 'Warranty Period', value: utils.monthFormat(deviceHistory?.device?.warranty_period) },
    { label: 'Supplier', value: deviceHistory?.device?.supplier },
    { label: 'Invoice Number', value: deviceHistory?.device?.invoice_number },
    {
      label: 'Original Price',
      value: utils.moneyFormat(deviceHistory?.device, deviceHistory?.device?.original_price || 0),
      unit: deviceHistory?.device?.original_price_unit
    },
    { label: 'Allotment Time', value: utils.monthFormat(deviceHistory?.device?.allotment_time) },
    {
      label: 'Remaining Price',
      value: utils.moneyFormat(deviceHistory?.device, deviceHistory?.device?.remaining_price || 0),
      unit: deviceHistory?.device?.original_price_unit
    },
    { label: 'Assignee Name', value: deviceHistory?.device?.assignee_name },
    {
      label: 'Borrow Date',
      value: deviceHistory?.device?.borrow_date ? utils.withTimeZone(deviceHistory?.device?.borrow_date, utils.dateFormat()) : ''
    },
    { label: 'Status', value: statusDeviceI18n(deviceHistory?.device?.status) },
    {
      label: 'Return Date',
      value: deviceHistory?.device?.return_date ? utils.withTimeZone(deviceHistory?.device?.return_date, utils.dateFormat()) : ''
    },
    {
      label: 'Manager Notes',
      value: deviceHistory?.device?.manager_notes,
      hidden: role.user.value === auth.userInfo.role
    }
  ]
  const titleInfo = `#${deviceHistory?.device?.id}: ${deviceHistory?.device?.name}`

  if (loading) {
    return (
      <div className="flex justify-center mt-4">
        <Spin></Spin>
      </div>
    )
  }

  return (
    <>
      <ContentDetails
        data={info}
        title={titleInfo}
        description={deviceHistory?.device?.description}
        photos={deviceHistory?.photos}
        handleGoBack={goBack}
      />
    </>
  )
}

export default HistoryDeviceDetails
