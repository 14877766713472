import { DeviceFilterType } from '../../types/deviceType'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FilterPayload } from '../../types/requestType'

const initialState: DeviceFilterType = {
  device_type: '',
  status: null,
  category: null,
  purchase_date: '',
  remaining_price: 0,
  borrow_date: '',
  return_date: '',
  url: '',
  path_name: '',
  key_word: ''
}

const deviceFilterSlice = createSlice({
  name: 'deviceFilterSlice',
  initialState,
  reducers: {
    handleSelectFilterDevice: (state, action: PayloadAction<FilterPayload>) => {
      switch (action.payload.type) {
        case 'device_type':
          if (state.device_type !== action.payload.value) {
            state.device_type = action.payload.value
          }
          break
        case 'status':
          if (state.status !== action.payload.value) {
            state.status = action.payload.value
          }
          break
        case 'category':
          if (state.category !== action.payload.value) {
            state.category = action.payload.value
          }
          break
        case 'purchase_date':
          if (state.purchase_date !== action.payload.value) {
            state.purchase_date = action.payload.value
          }
          break
        case 'remaining_price':
          if (state.remaining_price !== action.payload.value) {
            state.remaining_price = action.payload.value
          }
          break
        case 'borrow_date':
          if (state.borrow_date !== action.payload.value) {
            state.borrow_date = action.payload.value
          }
          break
        case 'return_date':
          if (state.return_date !== action.payload.value) {
            state.return_date = action.payload.value
          }
          break
        default:
          break
      }
    },
    resetFilterDevice: (state) => {
      state.device_type = ''
      state.status = null
      state.category = null
      state.purchase_date = ''
      state.remaining_price = 0
      state.borrow_date = ''
      state.return_date = ''
      state.url = ''
      state.path_name = ''
      state.key_word = ''
    },
    handleUrlDevice: (state, action: PayloadAction<string>) => {
      state.url = action.payload.includes('?') ? action.payload.slice(action.payload.indexOf('?'), action.payload.length).replaceAll('amp;', '') : ''
    },
    handlePathName: (state, action: PayloadAction<string>) => {
      state.path_name = action.payload
    },
    handleSetKeyWord: (state, action: PayloadAction<string>) => {
      state.key_word = action.payload
    }
  }
})

export const { handleSelectFilterDevice, resetFilterDevice, handleUrlDevice, handleSetKeyWord, handlePathName } = deviceFilterSlice.actions
export default deviceFilterSlice.reducer
