import { apiConstants } from './ApiConstants'
import { ApiServices } from './ApiServices'

export const UserServices = {
  async getListUsers (page?: number, keywords?: string, role?: string, sortParams?: any) {
    return await ApiServices().get(`${apiConstants.USERS}`, {
      params: {
        page,
        keywords,
        role,
        sort_order: sortParams
      }
    })
  },
  async updateUser (id: number, data: any) {
    return await ApiServices().patch(`${apiConstants.USERS}/${id}/update_role`, {
      role: data
    })
  },
  async removeUser (id: number) {
    return await ApiServices().delete(`${apiConstants.USERS}/${id}/remove_user`)
  },
  getDetail: async () => {
    const res = await ApiServices().get(`${apiConstants.USERS_PROFILE}`)
    return res
  }
}
