import { notification } from 'antd'
import { storage } from '../constants/storage'
import { routesObj } from '../routes/routes'
import moment from 'moment-timezone'
import i18n from '../lang/i18n'

notification.config({
  placement: 'topRight',
  bottom: 50,
  duration: 3,
  rtl: false,
  maxCount: 1
})

export const utils = {
  getStorage (key: string) {
    return localStorage.getItem(key)
  },
  setStorage (key: string, value: string) {
    return localStorage.setItem(key, value)
  },
  deleteStorage (key: string) {
    return localStorage.removeItem(key)
  },
  convertObjectToArrayData (object) {
    return Object.keys(object).map((key) => object[key])
  },
  handleError (error: any) {
    if (typeof error === 'string') {
      notification.error({
        message: i18n.t('message.history.error'),
        description: `${error || i18n.t('message.history.handleError')}`
      })
      return
    }
    if (!process.env.NODE_ENV) {
      console.error(error)
    }

    notification.error({
      message: i18n.t('message.history.error'),
      description: `${error.response?.data?.meta?.errors || i18n.t('message.history.handleError')}`
    })
  },
  sortAction (a: any, b: any, key: any) {
    a = isNaN(Number(a[key])) ? a[key].toLowerCase() : a[key]
    b = isNaN(Number(b[key])) ? b[key].toLowerCase() : b[key]
    return a > b ? -1 : b > a ? 1 : 0
  },
  logout () {
    const keysRemove = [
      storage.CURRENT_TENANT_ID,
      storage.ACCESS_TOKEN,
      storage.REFRESH_TOKEN,
      storage.ID_TOKEN,
      storage.ID_USER
    ]
    keysRemove.forEach((item) => {
      utils.deleteStorage(item)
    })
    window.location.href = `${routesObj.login.path}`
  },
  handleErrorNotify (error: string, message: string) {
    notification.error({
      message,
      description: error
    })
  },
  handleSuccessNotify (message: string) {
    notification.success({
      message: 'Successful',
      description: message
    })
  },
  convertDataObjectToArray (obj: any) {
    return Object.keys(obj).map((key: any) => obj[key])
  },
  formatMoney (n: number) {
    const decimal = n.toString().length - n.toString().indexOf('.') - 1
    if (!n.toString().includes('.')) {
      return n.toFixed(0).replace(/./g, function (c, i, a) {
        return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c
      })
    } else {
      return n.toFixed(decimal).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    }
  },
  formatMoneyNonDecimal (n: number, decimal: number) {
    return n.toFixed(decimal).replace(/./g, function (c, i, a) {
      return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c
    })
  },
  formatMoneyDecimal (n: number, decimal: number) {
    return n.toFixed(decimal).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
  },
  formatMoneyDetails (n: any) {
    return (Math.round(n * 100) / 100).toLocaleString()
  },
  moneyFormat (record: any, value: number) {
    if (!record) return

    const unit = record.original_price_unit?.toUpperCase() || ''
    return `${utils.formatMoneyDetails(value)} ${unit}`
  },
  monthFormat (value: any) {
    return value ? `${(Math.round(value * 100) / 100)} ${i18n.t('month')}` : ''
  },
  withTimeZone (datetime: string, format: string) {
    const time = moment(datetime)
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
    return time.tz(timeZone).format(format)
  },
  dateFormat () {
    return 'YYYY/MM/DD'
  },
  dateTimeFormat () {
    return 'YYYY/MM/DD HH:mm'
  }
}
