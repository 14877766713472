import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import type { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface'
import { useEffect, useState } from 'react'
import BaseModal from '../../components/BaseModal'
import BaseTable from '../../components/BaseTable'
import { useAppSelector } from '../../hooks'
import { UserServices } from '../../services/UserServices'
import { utils } from '../../utils/utils'
import EditRole from './Component/EditRole'
import { filterScreen } from '../../constants/filterScreen'
import { roleI18n } from '../../constants/role'
import { configuration } from '../../configuration'
import { TableParamsType } from '../../types/tableParamsType'
import { UserType } from '../../types/userType'
import { useRouter } from '../../hooks/useRouter'
import DeleteForm from './Component/DeleteForm'
import { useTranslation } from 'react-i18next'

function Users () {
  const router: any = useRouter()
  const { t } = useTranslation()
  const queryParams = new URLSearchParams(window.location.search)
  const pageCurrent = Number(queryParams.get('page') || 1)
  const textSearch = queryParams.get('keywords') || ''
  const [currentPage, setCurrentPage] = useState(pageCurrent)
  const auth = useAppSelector((state) => state.auth)
  const userFilter = useAppSelector((state) => state.userFilter)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [dataRecord, setDataRecord] = useState({})

  const [listUser, setListUser] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [loading, setLoading] = useState(true)
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false)
  const handleActions = (record: any) => {
    setDataRecord(record)
    setIsModalVisible(true)
  }
  const handleDelete = (record: any) => {
    setDataRecord(record)
    setDeleteModalVisible(true)
  }
  const [tableParams, setTableParams] = useState<TableParamsType>({
    sortField: 'id',
    pagination: {
      current: 1
    }
  })

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<UserType>
  ) => {
    setTableParams({
      sortField: 'id',
      pagination: {
        current: 1
      },
      filters,
      ...sorter
    })
  }
  const handleFilter = () => {
    router.push({
      pathname: router.location.pathname,
      search: `?${new URLSearchParams({ ...router.query, page: '1' }).toString()}`
    })
  }
  /* eslint-disable @typescript-eslint/dot-notation */
  const fetchListUser = async (page = 1, keywords, role) => {
    try {
      const fieldParams = tableParams['order'] !== undefined ? tableParams['columnKey'] : tableParams['sortField']
      const orderParams = tableParams['order'] === 'ascend' ? 'asc' : 'desc'
      const sortParams = `${fieldParams} ${orderParams}`

      const response = (await UserServices.getListUsers(page, keywords, role, sortParams)).data || []
      setListUser(response.users)
      setTotalItems(response.meta.total_result)
    } catch (error) {
      utils.handleError(error)
    } finally {
      setLoading(false)
    }
  }
  /* eslint-disable @typescript-eslint/dot-notation */

  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 130,
      sorter: true
    },
    {
      title: t('userForm.user'),
      dataIndex: 'full_name',
      key: 'full_name',
      width: 300,
      render: (_: any, record: any) => (
        <div className="truncate max-w-[260px]" title={record.full_name}>{record.full_name}</div>
      ),
      sorter: true
    },
    {
      title: t('userForm.email'),
      dataIndex: 'email',
      key: 'email',
      width: 500,
      render: (_: any, record: any) => (
        <div className="truncate max-w-[460px]" title={record.email}>{record.email}</div>
      ),
      sorter: true
    },
    {
      title: t('userForm.role'),
      dataIndex: 'role',
      key: 'role',
      width: 250,
      render: (_: any, record: any) => (
        <div className="d-flex">{roleI18n(record.role)}</div>
      ),
      sorter: true
    },
    {
      title: t('userForm.action'),
      align: 'center',
      render: (record: any) => {
        const isEditable = record.id !== auth.userInfo.id && !record.is_representative

        return isEditable
          ? (
          <>
            <EditOutlined
              className={'text-xl px-2 hover:text-blue-400'}
              onClick={() => handleActions(record)}
            />
            <DeleteOutlined
              className={'text-xl px-2 hover:text-blue-400'}
              onClick={() => handleDelete(record)}
            />
          </>
            )
          : null
      }
    }
  ]

  useEffect(() => {
    fetchListUser(Number(router.query.page || 1), textSearch, userFilter.role)
    setCurrentPage(Number(router.query.page || 1))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    JSON.stringify(tableParams),
    JSON.stringify(router.query),
    userFilter.role
  ])

  useEffect(() => {
    document.title = `Devices management - ${t('pageTitle.users')}`
  }, [])

  if (loading) {
    return (
      <div className="flex justify-center mt-4">
        <Spin></Spin>
      </div>
    )
  }

  return (
    <div>
      <BaseTable
        columns={tableColumns}
        dataSource={listUser}
        total={totalItems}
        pageSize={configuration.pageSize}
        callbackFilter={handleFilter}
        filterScreen={filterScreen.users}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        handleTableChange={handleTableChange}
        isScrollTable={false}
      />
      {isModalVisible && (
        <BaseModal
          visible={isModalVisible}
          handleCancel={() => setIsModalVisible(false)}
          content={
            <EditRole
              dataRecord={dataRecord}
              forceReGetListUser={() => {
                setIsModalVisible(false)
                fetchListUser(pageCurrent, textSearch, userFilter.role)
              }}
            />
          }
          title={t('updateRole')}
          footer={null}
        ></BaseModal>
      )}
      {isDeleteModalVisible && (
        <BaseModal
          visible={isDeleteModalVisible}
          handleCancel={() => setDeleteModalVisible(false)}
          content={
            <DeleteForm
              dataRecord={dataRecord}
              forceRequestList={() => {
                setDeleteModalVisible(false)
                fetchListUser(pageCurrent, textSearch, userFilter.role)
              }}
              handleCancelModal={() => {
                setDeleteModalVisible(false)
              }}
            />
          }
          title={t('removeUser')}
          width={350}
          footer={null}
          bodyStyle={{ padding: '5px 24px 14px' }}
        />
      )}
    </div>
  )
}

export default Users
