import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DeviceHistoryType } from '../../types/deviceHistoryType'

interface SelectedDeviceHistoryType {
  selectedDeviceHistory: DeviceHistoryType
  url: string
}
const initialState: SelectedDeviceHistoryType = {
  selectedDeviceHistory: {
    id: '0',
    user_name: '',
    note: '',
    updated_at: 'Aug 05 2020',
    device: {}
  },
  url: ''
}

const deviceHistorySlice = createSlice({
  name: 'deviceHistorySlice',
  initialState,
  reducers: {
    selectDeviceHistory: (state, action: PayloadAction<DeviceHistoryType>) => {
      state.selectedDeviceHistory = action.payload
    },
    handleUrlDeviceHistory: (state, action: PayloadAction<string>) => {
      state.url = action.payload.includes('?') ? action.payload.slice(action.payload.indexOf('?'), action.payload.length).replaceAll('amp;', '') : ''
    }
  }
})

export const { selectDeviceHistory, handleUrlDeviceHistory } = deviceHistorySlice.actions
export default deviceHistorySlice.reducer
