import { Button, Col, Form, Input, Row, Select, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { utils } from '../../../../utils/utils'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { TenantServices } from '../../../../services/TenantServices'
import { UserCompany } from '../../../../services/UserCompany'
import { UserServices } from '../../../../services/UserServices'
import { updateUserInfo } from '../../../../redux/slices/authSlice'
import { useAppDispatch } from '../../../../hooks'
import { useTranslation } from 'react-i18next'

const FormItem = (props: any) => {
  const { t } = useTranslation()
  const { forceRefeshTable, tenant } = props
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [description, setDescription] = useState('')
  const isCreate = Boolean(!Object.keys(tenant).length)
  const [listUser, setListUser] = useState([])
  const [loading, setLoading] = useState(true)
  const dispatch = useAppDispatch()

  const dataUserSelect = tenant && tenant?.selected_user_list?.map((user: any) => {
    return user.id
  })

  const getUserInfo = async () => {
    try {
      const { data } = await UserServices.getDetail()
      dispatch(updateUserInfo(data.user))
    } catch (e) {
      utils.handleError(e)
    }
  }

  const handleFinish = async (value: any) => {
    try {
      setIsSubmitting(true)
      if (isCreate) {
        const formData = { ...value, description }
        await TenantServices.createNewTenant(formData)
        utils.handleSuccessNotify(t('message.tenantForm.createSuccess'))
      } else {
        const formData = { ...value, description }
        await TenantServices.updateTenant(tenant.id, formData)
        utils.handleSuccessNotify(t('message.tenantForm.updateSuccess'))
      }
      forceRefeshTable && forceRefeshTable()
    } catch (e) {
      utils.handleError(e)
    } finally {
      setIsSubmitting(false)
      getUserInfo()
    }
  }
  const fetchListUser = async () => {
    try {
      const response = (await UserCompany.getListUsersCompany()).data || []
      const userData = response.data.users.map((user: any) => {
        return {
          label: user.full_name,
          value: user.id
        }
      })
      setListUser(userData)
    } catch (error) {
      utils.handleError(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchListUser()
    const dataForm = { ...tenant, user_ids: dataUserSelect }
    if (!isCreate) {
      form.setFieldsValue({ ...dataForm })
    }
  }, [JSON.stringify(tenant)])

  if (loading) {
    return (
      <div className="flex justify-center mt-4">
        <Spin></Spin>
      </div>
    )
  }

  return (
    <Form
      scrollToFirstError
      form={form}
      layout="vertical"
      onFinish={handleFinish}
    >
      <Row gutter={20}>
        <Col span={24}>
          <Form.Item
            label={t('message.tenantForm.name')}
            name="name"
            required
            rules={[{ required: true, message: t('message.requiredRule') }]}
          >
            <Input maxLength={250} autoComplete="off" />
          </Form.Item>
        </Col>
        <Col span={24}>
        <Form.Item label={t('message.tenantForm.selectUser')} name="user_ids">
          <Select
            mode="multiple"
            style={{ width: '100%', marginBottom: '20px' }}
            placeholder={t('message.tenantForm.placeholder')}
            options={listUser.map((user) => ({
              ...user,
              disabled: dataUserSelect?.includes(user.value)
            }))}
            value={dataUserSelect}
          />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t('message.tenantForm.description')} name="description">
            <CKEditor
              editor={ClassicEditor}
              data={isCreate ? '' : tenant.description}
              onChange={(_: any, editor: any) => {
                setDescription(editor.getData())
              }} />
          </Form.Item>
        </Col>
      </Row>
      <Button
        type="primary"
        htmlType="submit"
        loading={isSubmitting}
        className="block ml-auto"
      >
        {isCreate ? t('create') : t('update')}
      </Button>
    </Form>
  )
}

export default FormItem
