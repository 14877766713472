import {
  LogoutOutlined,
  UserOutlined,
  UserSwitchOutlined
} from '@ant-design/icons'
import { Dropdown, Menu, MenuProps, Space } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from '../../../../hooks'

import { routesObj } from '../../../../routes/routes'
import { utils } from '../../../../utils/utils'
import { AuthServices } from '../../../../services/AuthServices'

import './index.scss'
import { useTranslation } from 'react-i18next'
import { AuthOtherService } from '../../../../services/AuthOtherService'
import { storage } from '../../../../constants/storage'

type MenuItem = Required<MenuProps>['items'][number]

interface MenuOnClickType {
  key: React.Key
}

function getItem (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label
  } as MenuItem
}

export const NavProfile = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const auth = useAppSelector((state) => state.auth)
  const userId: any = utils.getStorage(storage.ID_USER) || ''

  const handleSyncLogout = async () => {
    await AuthOtherService.syncLogout(userId)
  }
  const handleClickMenuItem = ({ key }: MenuOnClickType) => {
    switch (key) {
      case '/logout':
        logOut()
        handleSyncLogout()
        break
      default:
        history.push(routesObj?.userProfile.path)
        break
    }
  }

  const logOut = async () => {
    await AuthServices.logout().then((_res) => {
      utils.logout()
    }).catch(() => {
      utils.logout()
    })
  }

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-body">
        <Menu
          style={{ boxShadow: 'none' }}
          items={[
            getItem(
              t('viewProfile'),
              routesObj?.userProfile.path,
              <UserOutlined />
            ),
            getItem(t('signOut'), '/logout', <LogoutOutlined />)
          ]}
          onClick={handleClickMenuItem}
        ></Menu>
      </div>
    </div>
  )
  const currentUsername = auth.userInfo?.full_name || ''

  return (
    <Dropdown
      placement="bottomRight"
      overlay={profileMenu}
      trigger={['click']}
      className="profile-items"
    >
      <span style={{ color: '#f1f1f1' }} className='cursor-pointer hover:text-blue-400'>
        <Space>
        <div className="truncate max-w-[200px]">
          {currentUsername}
        </div>
        </Space>
        <UserSwitchOutlined
          className={'text-xl pr-4 text-red ml-2'}
        />
      </span>
    </Dropdown>
  )
}

export default NavProfile
