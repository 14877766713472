import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  role: ''
}

const userFilterSlice = createSlice({
  name: 'userFilterSlice',
  initialState,
  reducers: {
    handleSelectRole: (state, action: PayloadAction<string>) => {
      state.role = action.payload
    },
    resetFilterUser: (state) => {
      state.role = ''
    }
  }
})

export const { handleSelectRole, resetFilterUser } = userFilterSlice.actions
export default userFilterSlice.reducer
