import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface KeysMenu {
  keys: any
}
const initialState: KeysMenu = {
  keys: []
}

const keysMenuSlice = createSlice({
  name: 'keysMenuSlice',
  initialState,
  reducers: {
    handleKeysMenu: (state, action: PayloadAction<any>) => {
      state.keys = action.payload
    }
  }
})

export const { handleKeysMenu } = keysMenuSlice.actions
export default keysMenuSlice.reducer
