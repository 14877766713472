import type { StepsProps } from 'antd'
import { Popover, Steps } from 'antd'
import './index.scss'

interface IStep {
  value: string
  label: string
}

interface IBaseStep {
  steps: IStep[]
  current: number
}

const { Step } = Steps
const customDot: StepsProps['progressDot'] = (dot) => (
  <Popover>{dot}</Popover>
)

const BaseStep = (props: IBaseStep) => {
  const {
    steps,
    current
  } = props
  return (
    <Steps current={current} progressDot={customDot}>
      {steps.map(step => (
        <Step key={step.value} title={step.label}/>
      ))}
    </Steps>
  )
}

export default BaseStep
