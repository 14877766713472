import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRouter } from '../../../hooks/useRouter'
import { priorityI18n, statusRequestI18n, trackerI18n } from '../../../constants/request'
import { role } from '../../../constants/role'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { RequestServices } from '../../../services/RequestServices'
import ContentDetails from '../components/ContentDetails'
import { utils } from '../../../utils/utils'
import { pageNotFound, routesAdminObj } from '../../../routes/routes'
import { Spin } from 'antd'
import { selectRequest } from '../../../redux/slices/requestSlice'
import { useTranslation } from 'react-i18next'

const Details = () => {
  const { t } = useTranslation()
  const params = useParams<any>()
  const auth = useAppSelector((state) => state.auth)
  const { selectedRequest } = useAppSelector((state) => state.request)
  const dispatch = useAppDispatch()
  const router = useRouter()
  const [loading, setLoading] = useState(true)

  const getData = async () => {
    try {
      const { data } = await RequestServices.getDetail(params.id)
      dispatch(selectRequest(data.request))
    } catch (error) {
      utils.handleError(error)
      router.history.push(pageNotFound)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    document.title = `Devices management - ${t('pageTitle.requestsDetails')}`
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goBack = () => {
    const state: any = router.history.location.state || {}
    router.push({
      pathname: routesAdminObj.requests.path,
      search: state.data || ''
    })
  }

  const info = [
    { label: 'Id', key: 'id', value: selectedRequest?.id },
    { label: t('requests.deviceName'), key: 'device_name', value: selectedRequest?.device_name },
    { label: t('requests.authorName'), value: selectedRequest?.author_name },
    { label: t('requests.assigneeName'), value: selectedRequest?.assignee_name },
    { label: t('requests.tracker'), value: trackerI18n(selectedRequest?.tracker) },
    { label: t('requests.priority'), value: priorityI18n(selectedRequest?.priority) },
    { label: t('requests.status'), value: statusRequestI18n(selectedRequest?.status) },
    { label: t('requests.startDate'), value: selectedRequest?.start_date },
    { label: t('requests.dueDate'), value: selectedRequest?.due_date },
    { label: t('requests.decision'), value: selectedRequest?.decision },
    {
      label: t('requests.managerNotes'),
      value: selectedRequest?.manager_notes,
      hidden: role.user.value === auth.userInfo.role
    }
  ]
  const infoDescription = selectedRequest?.description
  const infoTitle = `#${selectedRequest?.id}: ${selectedRequest?.title}`

  if (loading) {
    return (
      <div className="flex justify-center mt-4">
        <Spin></Spin>
      </div>
    )
  }

  return (
    <ContentDetails
      data={info}
      title={infoTitle}
      description={infoDescription}
      dataRequest={selectedRequest}
      handleGoBack={goBack}
      isRequestDetail={true}
    ></ContentDetails>
  )
}

export default Details
