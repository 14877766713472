import { CaretLeftOutlined, CheckOutlined } from '@ant-design/icons'
import { Button, Card, Col, Image, Row } from 'antd'
import React, { useState, useEffect } from 'react'
// import { useParams } from 'react-router-dom'
import { useRouter } from '../../../../hooks/useRouter'
import BaseModal from '../../../../components/BaseModal'
import CloseRequestForm from '../CloseRequestForm'
import { useAppSelector } from '../../../../hooks'
import { role } from '../../../../constants/role'
import { routesObj } from '../../../../routes/routes'
import { utils } from '../../../../utils/utils'
import BaseStep from '../../../../components/BaseStep'
import { COMMON_STATUSES, COMMON_STATUS_OBJECT } from '../../../../constants/request'
import './index.scss'
import { useTranslation } from 'react-i18next'

interface IContentDetails {
  data?: any
  title?: string
  description?: string
  photos?: any
  handleGoBack?: () => void
  dataRequest?: any
  isRequestDetail?: boolean
}

export default function ContentDetails (props: IContentDetails) {
  const {
    data = {},
    title = '',
    description = '',
    photos = [],
    dataRequest = {},
    isRequestDetail = false,
    handleGoBack
  } = props
  const { t } = useTranslation()
  const router = useRouter()
  const auth = useAppSelector((state) => state.auth)
  const [isCloseModalVisible, setCloseModalVisible] = useState<boolean>()

  let statuses = []
  const handleStatus = [COMMON_STATUS_OBJECT.REJECT.value, COMMON_STATUS_OBJECT.CLOSED.value]
  statuses = COMMON_STATUSES.filter((item: { value: string }) => !handleStatus.includes(item.value))

  if (handleStatus.includes(dataRequest.status)) {
    if (dataRequest.prev_status) {
      const indexPrevStatus = statuses.findIndex((item: { value: string }) => item.value === dataRequest.prev_status)
      statuses = statuses.slice(0, indexPrevStatus + 1)
    }
    statuses.push({
      value: dataRequest.status,
      label: COMMON_STATUSES.find((item: { value: string }) => item.value === dataRequest.status).label
    })
  } else {
    statuses.push({ value: 'finish', label: t('finish') })
  }

  let colStep = 0
  if (statuses.length === 2) {
    colStep = 12
  } else if (statuses.length === 3) {
    colStep = 16
  } else {
    colStep = 24
  }

  const currentStatus = statuses.findIndex(item => item.value === dataRequest.status)

  const isEnableModalClose =
    ['open', 'in_progress', 'approved_by_manager', 'approved_by_admin', 'preparing'].includes(dataRequest?.status) &&
      ['borrow', 'return', 'warranty_claim'].includes(dataRequest?.tracker) &&
        [role.admin.value, role.operator.value].includes(auth.userInfo.role)

  const openModalCloseRequest = () => {
    if (!isEnableModalClose) return
    setCloseModalVisible(true)
  }

  useEffect(() => {
    const index = data.map(e => e.label).indexOf('Assignee Name')
    if (window.location.pathname === 'devices' && window.performance && auth.userInfo.role === role.user.value && data[index]?.value !== undefined && data[index]?.value !== auth.userInfo.full_name) {
      utils.handleError(t('message.contentDetails.error'))
      router.history.push(
        routesObj.dashboard.path
      )
    }
  }, [])

  return (
    <>
      <Button
        onClick={handleGoBack}
        icon={<CaretLeftOutlined />}
        type="primary"
        className="mb-6"
      >
        {t('pageTitle.management.requests.back')}
      </Button>
      {isEnableModalClose && (
        <Button
          icon={<CheckOutlined />}
          type="primary"
          className="mb-6"
          style={{
            float: 'right'
          }}
          onClick={() => openModalCloseRequest()}
        >
          {t('pageTitle.management.requests.closeRequest')}
        </Button>
      )}
      <Card bodyStyle={{}}>
        <div className="text-3xl">{title}</div>
        <Row className="mt-6">
          {isRequestDetail &&
            <Col span={24} className="p-2 mb-4">
              <Row justify='center'>
                <Col span={colStep}>
                  <BaseStep
                    steps={statuses}
                    current={currentStatus}
                    />
                </Col>
              </Row>
            </Col>
          }
          {data.map((ele: any, index: number) => {
            if (ele.hidden) return
            return (
              <Col span={12} className="p-2" key={index}>
                <span className="font-bold mr-1">{ele.label}:</span>{' '}
                {ele.value || ''}
              </Col>
            )
          })}
          {description && (
            <Col span={24} className="p-2">
              <div className="font-bold mb-4">
                <span>{t('pageTitle.management.requests.description')}</span>
              </div>
              <div className="border-solid border border-slate-300">
                <div
                  className="p-4"
                  dangerouslySetInnerHTML={{ __html: description }}
                ></div>
              </div>
            </Col>
          )}
          {!!photos?.length && (
            <Col span={24} className="p-2">
              <span className="font-bold mr-1">Photos:</span>
              <div>
                <Image.PreviewGroup>
                  {photos?.map((photo: any, index: number) => {
                    return (
                      <Card
                        style={{ display: 'inline-flex', width: 200, height: 200, marginRight: 10, padding: 5 }}
                        className="items-center"
                        key={index}
                        bodyStyle={{ padding: 0, width: '100%', height: '100%' }}
                      >
                        <Image
                          key={index}
                          src={`${process.env.REACT_APP_BASE_URL_REQUEST}${photo.url}`}
                          className="p-1 items-center"
                          style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                        />
                      </Card>
                    )
                  })}
                </Image.PreviewGroup>
              </div>
            </Col>
          )}
        </Row>
      </Card>
      {isCloseModalVisible && (
        <BaseModal
          visible={isCloseModalVisible}
          handleCancel={() => setCloseModalVisible(false)}
          content={
            <CloseRequestForm
              dataRequest={dataRequest}
              forceRefeshTable={() => {
                setCloseModalVisible(false)
              }}
            />
          }
          title={'Update related device'}
          width={800}
          footer={null}
          bodyStyle={{ padding: '5px 24px 14px' }}
        />
      )}
    </>
  )
}
