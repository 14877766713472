import { useState } from 'react'
import { ArrowDownOutlined, SwapRightOutlined } from '@ant-design/icons'
import { Button, DatePicker, InputNumber, Select } from 'antd'
import { deviceTypes, statusDevice } from '../../constants/device'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { handleSelectFilterDevice } from '../../redux/slices/deviceFilterSlice'
import moment from 'moment'
import { dateFormat } from '../../constants/common'
import { DeviceServices } from '../../services/DeviceServices'
import { utils } from '../../utils/utils'
import { useTranslation } from 'react-i18next'

const DeviceFilter = (props: {callbackFilter?: any }) => {
  const { t } = useTranslation()
  const { callbackFilter } = props
  const { RangePicker } = DatePicker
  const dispatch = useAppDispatch()
  const [minRemainingPrice, setMinRemainingPrice] = useState(null)
  const [maxRemainingPrice, setMaxRemainingPrice] = useState(null)
  const auth = useAppSelector((state) => state.auth)

  const optionsCategory = [...deviceTypes, { value: 'all', label: t('common.all') }]
  const optionsStatus = [...statusDevice, { value: 'all', label: t('common.all') }]
  const remainingPriceValue = [minRemainingPrice, maxRemainingPrice]
  const device = useAppSelector((state) => state.deviceFilter)
  const queryParams = new URLSearchParams(window.location.search)
  const textSearch = queryParams.get('keywords') || device.key_word

  const handleFilterDevice = (value: any, type: string) => {
    if (type === 'min_remaining_price' || type === 'max_remaining_price') {
      if (type === 'min_remaining_price') {
        remainingPriceValue[0] = value
      }
      if (type === 'max_remaining_price') {
        remainingPriceValue[1] = value
      }

      remainingPriceValue[0] = remainingPriceValue[0] === null ? undefined : remainingPriceValue[0]
      remainingPriceValue[1] = remainingPriceValue[1] === null ? undefined : remainingPriceValue[1]

      type = 'remaining_price'
      value = remainingPriceValue
    }
    callbackFilter && callbackFilter({ value, type })
    dispatch(handleSelectFilterDevice({ value, type }))
  }

  const exportDevices = async (value: string, sortParams: string) => {
    try {
      await DeviceServices.exportCSVListDevices(value, sortParams)
    } catch (error) {
      utils.handleError(error)
    }
  }

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex space-x-6 filter">
          <Select
            value={device.category}
            style={{ width: 200 }}
            placeholder={t('pageTitle.personal.search.byCategory')}
            options={optionsCategory}
            onChange={(value) => handleFilterDevice(value, 'category')}
          />
          <Select
            value={device.status}
            style={{ width: 200 }}
            placeholder={t('pageTitle.personal.search.byStatus')}
            options={optionsStatus}
            onChange={(value) => handleFilterDevice(value, 'status')}
          />
          {auth.userInfo.role !== 'user' &&
            <>
              <InputNumber
                value={device.remaining_price === 0 ? null : device.remaining_price[0]}
                style={{ width: 200 }}
                title= {t('pageTitle.personal.search.minRemainingPrice')}
                placeholder={t('pageTitle.personal.search.minRemainingPrice')}
                min={0}
                onChange={(value) => {
                  handleFilterDevice(value, 'min_remaining_price')
                  setMinRemainingPrice(value)
                }}
              />
              <SwapRightOutlined className="icon-date" style={{ fontSize: '16px', color: 'rgba(0,0,0,.25)', marginTop: '7px' }}/>
              <InputNumber
                value={device.remaining_price === 0 ? null : device.remaining_price[1]}
                style={{ width: 200 }}
                title={t('pageTitle.personal.search.maxRemainingPrice')}
                placeholder={t('pageTitle.personal.search.maxRemainingPrice')}
                min={0}
                onChange={(value) => {
                  handleFilterDevice(value, 'max_remaining_price')
                  setMaxRemainingPrice(value)
                }}
              />
            </>
          }
        </div>

          {auth.userInfo.role !== 'user' &&
            <Button
              type="primary"
              style={{ minWidth: 127.86, paddingLeft: 10 }}
              onClick={
                async () => await exportDevices(textSearch, 'id asc')
              }
              icon={<ArrowDownOutlined />}
            >
              {t('pageTitle.personal.exportFile')}
            </Button>
          }

      </div>
      <div className="flex space-x-6 mt-4">
        <RangePicker
          placeholder={[t('pageTitle.personal.search.purchaseDateFrom'), t('pageTitle.personal.search.purchaseDateTo')]}
          className='w-full'
          value={[
            device.purchase_date.length ? moment(`${device.purchase_date[0]}`, dateFormat) : null,
            device.purchase_date.length ? moment(`${device.purchase_date[1]}`, dateFormat) : null
          ]}
          onChange={value => {
            const purchaseDateRange = value
              ? value.map((v: any) => {
                return v.format('YYYY/MM/DD')
              })
              : []
            handleFilterDevice(purchaseDateRange, 'purchase_date')
          }}
        />
        <RangePicker
          placeholder={[t('pageTitle.personal.search.borrowDateFrom'), t('pageTitle.personal.search.borrowDateTo')]}
          className='w-full'
          value={[
            device.borrow_date.length ? moment(`${device.borrow_date[0]}`, dateFormat) : null,
            device.borrow_date.length ? moment(`${device.borrow_date[1]}`, dateFormat) : null
          ]}
          onChange={value => {
            const borrowDateRange = value
              ? value.map((v: any) => {
                return v.format('YYYY/MM/DD')
              })
              : []
            handleFilterDevice(borrowDateRange, 'borrow_date')
          }}
        />
        <RangePicker
          placeholder={[t('pageTitle.personal.search.returnDateFrom'), t('pageTitle.personal.search.returnDateTo')]}
          className='w-full'
          value={[
            device.return_date.length ? moment(`${device.return_date[0]}`, dateFormat) : null,
            device.return_date.length ? moment(`${device.return_date[1]}`, dateFormat) : null
          ]}
          onChange={value => {
            const returnDateRange = value
              ? value.map((v: any) => {
                return v.format('YYYY/MM/DD')
              })
              : []
            handleFilterDevice(returnDateRange, 'return_date')
          }}
        />
      </div>
    </div>
  )
}

export default DeviceFilter
